/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";

import TssHome from "./pages/tssHome";

import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Maps from "./pages/Map";
import Vehicles from "./pages/Vehicles"
import Search from "./pages/Search";
import Movements from "./pages/Movements";




import { Provider } from "react-redux";
import {Store} from "./redux/state/store/store";
  
function App() {
  return (
    <div className="App">
      <Provider store={Store}>
      <Switch>
        {/* <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} /> */}
        <Main>
          
          <Route exact path="/dashboard" component={Vehicles} />
          <Route exact path="/map" component={Maps} />
          <Route exact path="/TssHome" component={TssHome} />

          {/* Main Screens */}
          <Route exact path="/vehicles" component={Vehicles} />
          <Route exact path="/mapping" component={Search} />
          <Route exact path="/movements" component={Movements} />
    
          {/* End Main Screens */}

          {/* 
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} /> 
          */}
        
         <Redirect from="*" to="/dashboard" />
          {/* <Redirect from="*" to="/mapping" /> */}

        </Main>
      </Switch>
      </Provider>
    </div>
  );
}

export default App;
