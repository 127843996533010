// eslint-disable-next-line 
import React, { useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Link, Route } from "react-router-dom";
import './drop-down.css'
import './app.css'
import {
  OrbitSpinner,
} from 'react-epic-spinners'
import {
  AirportShuttle,
  MoreTime,
  BatteryUnknown,
  LocationOn,
  People,
  Apartment,
  Diversity1,
  Engineering,
  Domain,
  House,
  RunCircle,
  ExitToApp,
  EnergySavingsLeaf,
  Map,
  MyLocation,
  Layers,
  PersonOff,
  Info,
  OfflinePin,
  CellTower,
  HowToReg,
  Person,
  Boy, Workspaces,
  DirectionsCar,
  CarCrash,
  Grid3x3,
  Window,
  Work,
  FormatListNumbered,
  UnfoldMore, ArrowOutward, Refresh
} from '@mui/icons-material';
import TableView from 'react-table-view'

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Search from "../../pages/Search/";
import { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal';
import {
  Row, Col,
  Card,
  Avatar,
  Typography,
  Spin,
  Input,
  Button,
  DatePicker,
  Select, Divider, message
} from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  SearchOutlined,
  StarOutlined,
  TwitterOutlined,
  FacebookFilled,
  UserOutlined
} from "@ant-design/icons";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Odometer from '../../assets/images/odometer.png'
import kilometer from '../../assets/images/kilometer.png'

import { tssTheme } from '../../constants'
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';

// filtering
import {
  siteSelectionFilter, AssignedSiteFiltered,
  handleMovementFiltered, handleInsideRangeFiltered,
  handleOnDutyFiltered, EngineStatusFiltered,
  EngineIdleFiltered, handleNameSort,
  handleInsideSort, handleIdleTimeSort,
  handleDepartmentSort, handleJobSort,
  handleKMsSort,
  handleAccommodationSort, handleTeamSort,
  handleAssignedSitesSort, handleMovementSort, handleEngineStatusSort,
  removeSiteDuplicate, removeDepartmentDuplicate, removeJobDuplicate
} from './searchFilters'

// import Button from 'react-bootstrap/Button';
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import { Flex, Spacer, Center, Square, Box, Wrap, WrapItem, SimpleGrid, Stack, ButtonGroup, HStack } from '@chakra-ui/react'
import { ChevronDownIcon, PhoneIcon } from '@chakra-ui/icons'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Animate, AnimateGroup } from "react-simple-animate";

import GridView from './vehicleBoxView';

import { getData, filterData, testFetch } from './fetchData'
import Theme from '../../constants/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/state';
import { fontSize } from '@mui/system';
import ReactHover, { Trigger, Hover } from "react-hover";
import Multiselect from 'multiselect-react-dropdown';
import BoxView from '../BoxView'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Snackbar from '@mui/material/Snackbar';

import './'

const { Text } = Typography;
const { Option } = Select;

const containerStyle = {
  width: '100%',
  height: window.innerWidth / 2.5
};

const center = {
  lat: 25.2048,
  lng: 55.2708
};

function MyComponent() {
  const dispatch = useDispatch()
  const screenStates = useSelector(state => state.screenStates)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: tssTheme.APP.MapAPIKey
  })
  const [isLoading, setLoading] = React.useState(false)
  const [isFilterLoading, setFilterLoading] = React.useState(false)
  const [isDataLoaded, setDataLoaded] = React.useState(false)
  const [date, setDate] = React.useState("" + moment().format("YYYY-MM-DD"))

  const [type, setType] = React.useState("Human Resource")    //Equipment   //Human Resource
  const [assignedType, setAssignedType] = React.useState("All")   // Assigned
  const [movementType, setMovementType] = React.useState("All")   //Movement
  const [insideType, setInsideRangeType] = React.useState("All")    // Inside Range
  const [attendanceType, setAttendanceType] = React.useState("All")   //Attendance
  const [engineType, setEngineStatusType] = React.useState("All")   //Engine Status
  const [engineIdleType, setIdleType] = React.useState("All")       //Engine IDLE
  const [siteMenuItem, setSitesMenuItem] = React.useState("")   // Assigned
  const [dapartmentMenu, setDapartmentMenu] = React.useState("")   // Assigned
  const [treeValue, setTreeDataValue] = React.useState("");
  const [messageApi, contextHolder] = message.useMessage();

  // const [assignedType, setAssignedType] = React.useState("Assignment")    //Equipment   //Human Resource
  // const [movementType, setMovementType] = React.useState("Movement")    //Equipment   //Human Resource
  // const [insideType, setInsideRangeType] = React.useState("Inside Range")    //Equipment   //Human Resource
  // const [attendanceType, setAttendanceType] = React.useState("Attendance")    //Equipment   //Human Resource

  const [type_r, setType_R] = React.useState("Equipment")
  const [team, setTeam] = React.useState("")
  const [driver, setDriver] = React.useState("")
  const dateFormat = 'YYYY-MM-DD';
  const [teams, setTeams] = useState([])
  const [vehicleDetail, setVehicleDetail] = useState([])

  const [viewType, setViewType] = useState("2")  // 0 for Map or 1 for GRID
  const [width, setWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [SnackbarMsg, setSnackbarMsg] = useState(false);
  const [SnackbarShow, setSnackbarShow] = useState(false);

  const [engineONChecked, setEngineONChecked] = useState(false)
  const [engineOFFChecked, setEngineOFFChecked] = useState(false)
  const [idleChecked, setIdleChecked] = useState(false)
  const [engineALLChecked, setEngineALLChecked] = useState(false)

  const [assignedChecked, setAssignedChecked] = useState(false)
  const [notAssignedChecked, setNotAssignedChecked] = useState(false)
  const [noMovementChecked, setNoMovementChecked] = useState(false)
  const [humanALLChecked, setHumanALLChecked] = useState(false)
  const [insideRange, setInsideRange] = useState(false)

  const [searchQuery, setSearchQuery] = useState("");
  const [searchBy, setSearchBy] = useState("employee");

  const [get_assigned_sites, setAssignedSite] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [sitesList, setSitesList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [jobList, setJobList] = useState([])

  const [siteSelectionList, setSiteSelectionList] = useState([])
  const [jobSelectionList, setJobSelectionList] = useState([])
  const [departmentSelectionList, setDepartmentSelectionList] = useState([])

  const [dummyFilteredList, setDummyFilteredList] = useState([])
  const [dummyFilteredData1, setDummyFilteredData1] = useState([])

  const [filterOptions, setfilterOptions] = useState([
    { label: 'Human Resource', value: 'Human Resource' },
    { label: 'Equipment', value: 'Equipment' },
    { label: 'Engine ON ', value: 'Engine ON ' },
    { label: 'Engine Idle', value: 'Engine Idle' },
    { label: 'Assigned to Site', value: 'Assigned to Site' },
    { label: 'Movements', value: 'Movements' },
    { label: 'Inside Range', value: 'Inside Range' },
    { label: 'On Duty', value: 'On Duty' },
    { label: 'Select Site', value: 'Select Site' },
    { label: 'Select Department', value: 'Select Department' },
    { label: 'Select Job', value: 'Select Job' },
    { label: 'Date', value: 'Date' },
  ])
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [driversList, setListDriver] = useState([])
  const [playersList, setPlayersList] = useState([])

  const [assignedList, setAssignedList] = useState([])
  const [movementList, setMovementList] = useState([])
  const [insideList, setInsideList] = useState([])
  const [AttendanceList, setAttendanceList] = useState([])

  const [dataProp, setDataProp] = React.useState([])
  const [resource_history_id, setResourceHistoryId] = React.useState('')
  const [datetime, setDateTime] = React.useState('')

  const [humanCount, setHumanCount] = useState(0)
  const [equipmentCount, setEquipmentCount] = useState(0)
  const [sitesCount, setSitesCount] = useState(0)
  const [nameSort, setNameSort] = useState(0)

  const [viewItemShow, setItemShow] = useState(false);
  const [viewModelItem, setModelItem] = useState([]);

  const [show, setShow] = useState(false);

  const handleItemClose = () => setItemShow(false);
  const handleItemShow = () => setItemShow(true);

  const handleShow = () => setShow(true);

  const [data, setData] = useState([
    {
      latitude: 25.542364,
      longitude: 55.705690
    },
    {
      latitude: 24.057781,
      longitude: 51.771866
    },
    {
      latitude: 24.290865,
      longitude: 54.596333
    }
  ]);

  // Similar to componentDidMount and componentDidUpdate:

  useEffect(() => {
    filter(type, date);

    // console.log(''+getData(6))
    window.addEventListener("popstate", () => {
      dispatch(actionCreators.screenStates("dashboard"))
    });

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }

  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const onDateChange = (date, dateString) => {
    setSearchQuery("")
    setDate(dateString)
    filter(type, dateString);
  };

  const handleTeamChange = () => {
    <Select
      defaultValue={team}
      style={{
        width: 170,
      }}
      onChange={handleTeamChange}
    >
      {
        teams.map((teams) => {
          return <Option value={teams.team_name + "_" + teams.team_id} //teams.team_name==="CW-CON-00008"?null:
          > {teams.team_name}</Option>
        })
      }
    </Select>
  };

  const setTypeChange = (event) => {
    console.log(event);
    setType(event);
    if (event === "Equipment") {
      setType_R("Equipment")
    } else if (event === "Human Resource") {
      setType_R("Human Resource")
    } else if (event === "Both") {
      setType_R("Both")
    } else if (event === "Team") {
      setType_R("Team")
    }
    setTeam("")
    setDriver("")

    if (filteredList.length < 1)
      filter(event, date)
    else {
      filteringData(event)
    }

  }

  const SelectType = () => {
    return (

      <Dropdown style={{ marginLeft: 0 }}>
        <Dropdown.Toggle
          variant="secondary"
          style={{ height: 50, backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2 }}
        >
          {type}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          <Dropdown.Item style={{}}
            onClick={() => setTypeChange("Equipment")}>
            Equipment
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setTypeChange("Human Resource")} >Human Resource</Dropdown.Item>
          <Dropdown.Item onClick={() => setTypeChange("Both")} >Both</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const AssignmentType = () => {
    return (

      <Dropdown style={{}} onSelect={handleAssignedSiteFilter}>
        <Dropdown.Toggle
          variant="secondary"
          style={{
            backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2
            , fontSize: 15, height: 50
          }}
        >
          Assigned to site(s)? : {assignedType}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Assignment Types</Dropdown.Item> */}
          <Dropdown.Item style={{}}
            eventKey="Yes"
          >
            Yes
          </Dropdown.Item>
          <Dropdown.Item

            eventKey="No"
          >No</Dropdown.Item>

          <Dropdown.Item
            eventKey="All"
          >All</Dropdown.Item>

        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const MovementType = () => {
    return (

      <Dropdown style={{ marginLeft: 0 }} onSelect={handleWithMovement}>
        <Dropdown.Toggle
          variant="secondary"
          style={{
            backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2
            , fontSize: 15, height: 50
          }}
        >
          Movement(s)? :{movementType}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Movements</Dropdown.Item> */}
          <Dropdown.Item style={{}}
            eventKey="Yes"
          //  onClick={()=>{}}
          >
            Yes
          </Dropdown.Item>
          <Dropdown.Item eventKey="No"  >No</Dropdown.Item>
          <Dropdown.Item
            eventKey="All" >
            All</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const InsideRangeType = () => {
    return (

      <Dropdown style={{ marginLeft: 0 }} onSelect={handleInsideRangeYes}>
        <Dropdown.Toggle
          variant="secondary"
          style={{
            backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2
            , fontSize: 15, height: 50
          }}
        >
          Inside Range? :{insideType}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Inside Range</Dropdown.Item> */}
          <Dropdown.Item
            eventKey="Yes"
            style={{}}
            eventKey="Yes">
            Yes
          </Dropdown.Item>
          <Dropdown.Item eventKey="No"
          >
            No</Dropdown.Item>
          <Dropdown.Item eventKey="All"
          >
            All</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const AttendanceType = () => {
    return (

      <Dropdown style={{ marginLeft: 0 }} onSelect={handleOnDuty}>
        <Dropdown.Toggle
          variant="secondary"
          style={{ backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2, fontSize: 15, height: 50 }}
        >
          On Duty? :{attendanceType}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Attendance Types</Dropdown.Item> */}
          <Dropdown.Item eventKey="Yes"
            style={{}}
          >
            Yes
          </Dropdown.Item>
          <Dropdown.Item eventKey="No"
          >No</Dropdown.Item>

          <Dropdown.Item eventKey="All"
          >All
          </Dropdown.Item>

        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const EngineStatusType = () => {
    return (

      <Dropdown style={{ marginLeft: 0 }} onSelect={handleEngineON} >
        <Dropdown.Toggle
          variant="secondary"
          style={{ height: 50, backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2, fontSize: 15 }}
        >
          Engine On? :{engineType}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Engine Status</Dropdown.Item> */}
          <Dropdown.Item style={{}}
            eventKey="Yes" >
            Yes
          </Dropdown.Item>
          <Dropdown.Item eventKey="No" >No</Dropdown.Item>
          <Dropdown.Item eventKey="All" >All</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );
  }

  const IdleType = () => {
    return (

      <Dropdown style={{ marginLeft: 0, }} onSelect={handleEngineIdleYes}>
        <Dropdown.Toggle
          variant="secondary"
          style={{ height: 50, backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2, fontSize: 15 }}
        >
          Engine Idle? : {engineIdleType}
        </Dropdown.Toggle>
        <Dropdown.Menu variant="" style={{}}  >
          {/* <Dropdown.Item disabled>Engine Idle</Dropdown.Item> */}
          <Dropdown.Item style={{}}
            eventKey="Yes" >
            Yes
          </Dropdown.Item>
          <Dropdown.Item style={{}}
            eventKey="No" >
            No
          </Dropdown.Item>
          <Dropdown.Item eventKey="All" >All</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );

  }

  const handleClose = () => setShow(false);

  const handleActiveKey = (event) => {
    console.log(event)
    setTypeChange(event);
    if (event === "map") {
      setViewType("0")
    }
    setSearchQuery("")
  };

  const onChangeFilterBox = (value) => {

    if (value.some(item => (item.value === 'Human Resource')) && value.some(item => (item.value === 'Equipment'))) {
      // do something
      console.log(" value: Both")
      setTypeChange('Both')
    } else if (value.some(item => (item.value === 'Human Resource'))) {
      setTypeChange('Human Resource')
    } else if (value.some(item => (item.value === 'Equipment'))) {
      setTypeChange('Equipment')
    }
    // console.log(value.some(item => (item.value === 'Human Resource'  )))
    // setTypeChange(value[0].value)
    // console.log(value)

  }

  const mapEquipmentDetailView = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={10.5}>
          <Box>
            <AnimateGroup play>
              <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={0}>

                {/* <Flex >
        <Box flex='0.98' >
        </Box>
        <Box  >
           <Button onClick={()=>{setActiveMarker(null)}} 
             style={{backgroundColor:'#FFF',color:'#000',borderColor:'#D3D3D3',borderRadius:2,marginTop:0}}
            >
           {"Close"}
           </Button> 
        </Box>
      </Flex> */}

                <Row>

                  <Col >
                    {/* <Avatar  shape="square" size={50} icon={<UserOutlined />} />
            <br/> */}
                    {vehicleDetail.driver_name ?
                      <Text ><Text strong underline style={{ color: Theme.COLORS.PRIMARY }}>{vehicleDetail.driver_name}</Text></Text>
                      : null}
                  </Col>
                </Row>
              </Animate>


              <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={2}>
                <br />
                {vehicleDetail.device_id ?
                  <Text >      <Grid3x3 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    :   <Text strong>{vehicleDetail.device_id}</Text></Text>
                  : null}
                <br />
              </Animate>

              <Box>
                {vehicleDetail.hr_department ?
                  <Box>
                    <Domain sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_department}
                    </Text>
                  </Box>
                  : null}
              </Box>

              <Box>
                {vehicleDetail.hr_job ?
                  <Box>
                    <Work sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_job}
                    </Text>
                  </Box>
                  : null}
              </Box>



              <Box>
                {vehicleDetail.team_name ?
                  <Box>
                    <Diversity1 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.team_name}</Text>
                  </Box>
                  : null
                }
              </Box>

              <Box>
                {vehicleDetail.accommodation ?
                  <Box>
                    <House sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.accommodation}</Text>
                  </Box>
                  : null}
              </Box>


              <Box>
                {vehicleDetail.assigned_sites ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + vehicleDetail.assigned_sites}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>

              <br />
              <br />

              <div>
                <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={0}>
                  <Text strong underline>Fleet Information  </Text>
                </Animate>
                <br />

                <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={1}>
                  {vehicleDetail.fleet_name ?
                    <Text >
                      <DirectionsCar sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} /> : <Text strong>{vehicleDetail.fleet_name}</Text></Text>
                    : null}
                  <br />
                  {vehicleDetail.engine_status ?
                    <Text > <CarCrash sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} /> : <Text strong style={{ color: vehicleDetail.engine_status.toString() === "ON" ? Theme.COLORS.forestgreen : Theme.COLORS.RED }}>{vehicleDetail.engine_status.toString()}</Text> </Text>
                    : null}
                  <br />
                  {/* <Text >Fleet State: <Text strong style={{}}>{""+vehicleDetail.trip_state}</Text> </Text>
                 <br/>  */}
                </Animate>



                <Box>
                  <Avatar src={<Image src={kilometer}
                    style={{}} />}
                    size={25}
                  />
                  <Text > : {parseFloat("" + vehicleDetail.today_km).toFixed(2)}  (today)</Text>
                </Box>
                <Box>
                  {/* <Speed  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }}/> */}
                  <Avatar src={<Image src={Odometer}
                    style={{}} />}
                    size={25}
                  />
                  <Text > : {parseFloat("" + vehicleDetail.start_odometer).toFixed(2)} - {parseFloat("" + vehicleDetail.end_odometer).toFixed(2)}</Text>
                </Box>




                {vehicleDetail.engine_idle_time_with_no_movement ?
                  <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={4}>
                    <Text >Today's Idle Time With No Movement:  <Text strong>{parseFloat("" + vehicleDetail.engine_idle_time_with_no_movement).toFixed(2)}</Text></Text>
                  </Animate>
                  : null}

              </div>
              <br />
            </AnimateGroup>

          </Box>
        </Grid>


        <Grid item xs={1.5} style={{ backgroundColor: '' }} >

          <Grid container spacing={0.5} style={{ height: '100%', alignSelf: 'center', }}>
            <Grid item>
              <Link
                to={{
                  // pathname: '/mapping',
                  // // search: '?sort=name',
                  // // hash: '#the-hash',
                  // id:vehicleDetail.resource_history_id,
                  // place:vehicleDetail,
                  // params: { id: 88 },
                  // datetime:date
                }}

              ><MyLocation
                  onClick={() => {

                    dispatch(actionCreators.screenStates("mapping"))
                    setDataProp(vehicleDetail)
                    setResourceHistoryId(vehicleDetail.resource_history_id)
                    setDateTime(date)
                  }}
                  sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY, }} />
              </Link>
            </Grid>
            <Grid item >
              <CellTower sx={{ fontSize: 25, color: vehicleDetail.inside_range > 1 ? tssTheme.COLORS.forestgreen : tssTheme.COLORS.BLACK, }} />
            </Grid>
            <Grid item >
              {vehicleDetail.leave_status === "Present" ?
                <HowToReg sx={{ fontSize: 25, color: tssTheme.COLORS.forestgreen, }} /> :
                <Person sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY, }} />}
            </Grid>
            <Grid item >

              <Text style={{
                padding: ("" + vehicleDetail.movements_count).length === 1 ? 7 : 6,
                backgroundColor: vehicleDetail.inside_range < 1 ? tssTheme.COLORS.BLACK : tssTheme.COLORS.forestgreen,
                textAlign: 'center', fontSize: 12,
                color: tssTheme.COLORS.WHITE
              }}>
                {("" + vehicleDetail.movements_count)}
              </Text>

            </Grid>
          </Grid>
        </Grid>


      </Grid>
    )

  }



  const mapSiteView = () => {
    <Box flex='0.4' bg='green.500' style={{ marginTop: 20 }}>
      <AnimateGroup play>
        <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={0}>
          <Flex >
            <Box flex='0.98' >
            </Box>
            <Box  >
              <Button onClick={() => { setActiveMarker(null) }}
                style={{ backgroundColor: '#FFF', color: '#000', borderColor: '#D3D3D3', borderRadius: 2, marginTop: 5 }}
              >
                {"Close"}
              </Button>
            </Box>
          </Flex>
          <Row>

            <Col >
              {/* <Avatar  shape="square" size={50} icon={<UserOutlined />} />
            <br/> */}
              {vehicleDetail.site_name ?
                <Text ><Text strong underline style={{ color: Theme.COLORS.PRIMARY }}>{vehicleDetail.site_name}</Text></Text>
                : null}
            </Col>

          </Row>
        </Animate>

        <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={1}>
          {vehicleDetail.site ?
            <Text >Code:  <Text strong>{vehicleDetail.site}</Text></Text>
            : null}<br />
        </Animate>

      </AnimateGroup>

    </Box>

  }

  const mapVehicleDetailView = () => {
    try {

      return (
        vehicleDetail.tracking_type === "Human Resource" ? mapHumanDetailView() :
          vehicleDetail.tracking_type === "Equipment" ? mapEquipmentDetailView() : mapSiteView()
        // null 
      )

    } catch (e) {
      console.log('Error' + e)
    }

  }


  const mapHumanDetailView = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={10.5}>
          <Box>
            <AnimateGroup play>
              <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={0}>

                {/* <Flex >
              <Box flex='0.98' >
              </Box>
              <Box  >
                 <Button onClick={()=>{setActiveMarker(null)}} 
                   style={{backgroundColor:'#FFF',color:'#000',borderColor:'#D3D3D3',borderRadius:2,marginTop:0}}
                  >
                 {"Close"}
                 </Button> 
              </Box>
            </Flex> */}

                <Row style={{}}>

                  <Col >
                    {/* <Avatar  shape="square" size={50} icon={<UserOutlined />} />
                  <br/> */}
                    {vehicleDetail.driver_name ?
                      <Text style={{}} ><Text strong underline style={{ color: Theme.COLORS.PRIMARY }}>{vehicleDetail.driver_name}</Text></Text>
                      : null}
                  </Col>

                </Row>
              </Animate>


              <Box>
                {vehicleDetail.hr_department ?
                  <Box>
                    <Domain sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_department}
                    </Text>
                  </Box>
                  : null}
              </Box>

              <Box>
                {vehicleDetail.hr_job ?
                  <Box>
                    <Work sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_job}
                    </Text>
                  </Box>
                  : null}
              </Box>



              <Box>
                {vehicleDetail.team ?
                  <Box>
                    <Diversity1 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.team}</Text>
                  </Box>
                  : null
                }
              </Box>




              <Box>
                {vehicleDetail.employee_accommodation ?
                  <Box>
                    <House sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.employee_accommodation}</Text>
                  </Box>
                  : null}
              </Box>


              <Box>
                {vehicleDetail.assigned_sites ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + vehicleDetail.assigned_sites}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>

              {/* <Box>
      {vehicleDetail.movements_count?
       <Box>  
       <RunCircle  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} /> <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.movements_count} Movements </Text>
       </Box>
      : null }
    </Box>  */}

              <br />
            </AnimateGroup>





          </Box>
        </Grid>


        <Grid item xs={1.5} style={{ backgroundColor: '' }} >

          <Grid container spacing={0.5} style={{ height: '100%', alignSelf: 'center', }}>
            <Grid item>
              <Link
                to={{
                  // pathname: '/mapping',
                  // // search: '?sort=name',
                  // // hash: '#the-hash',
                  // id:vehicleDetail.resource_history_id,
                  // place:vehicleDetail,
                  // params: { id: 88 },
                  // datetime:date
                }}

              >    <Tooltip title="Live Track">
                  <MyLocation onClick={() => {
                    dispatch(actionCreators.screenStates("mapping"))
                    setDataProp(vehicleDetail)
                    setResourceHistoryId(vehicleDetail.resource_history_id)
                    setDateTime(date)
                  }} sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY, }} />
                </Tooltip>
              </Link>
            </Grid>

            <Tooltip title="Inside Range">
              <Grid item >
                <CellTower sx={{ fontSize: 25, color: vehicleDetail.inside_range > 1 ? tssTheme.COLORS.forestgreen : tssTheme.COLORS.BLACK, }} />
              </Grid>
            </Tooltip>

            <Tooltip title="Attendance">
              <Grid item >
                {vehicleDetail.leave_status === "Present" ?
                  <HowToReg sx={{ fontSize: 25, color: tssTheme.COLORS.forestgreen, }} /> :
                  <Person sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY, }} />}
              </Grid>
            </Tooltip>

            <Tooltip title="Total Movements">
              <Grid item >
                <Text style={{
                  padding: ("" + vehicleDetail.movements_count).length === 1 ? 7 : 6,
                  backgroundColor: vehicleDetail.inside_range < 1 ? tssTheme.COLORS.BLACK : tssTheme.COLORS.forestgreen,
                  textAlign: 'center', fontSize: 12,
                  color: tssTheme.COLORS.WHITE
                }}>
                  {("" + vehicleDetail.movements_count)}
                </Text>

              </Grid>
            </Tooltip>

          </Grid>
        </Grid>


      </Grid>

    )
  }

  const handleSearchChange = (event) => {
    const query = event.target.value
    const searchBy1 = searchBy
    setSearchQuery(query);

    var filtertedData = type === "Human Resource" ?
      filteredList.filter(item => item.tracking_type === 'Human Resource') :
      filteredList.filter(item => item.tracking_type === 'Equipment')


    const searchList = filtertedData.filter((item) => {
      return searchBy1 === "employee" ? ("" + item.driver_name).toLowerCase().indexOf(query.toLowerCase()) !== -1
        : searchBy1 === "department" ? ("" + item.hr_department).toLowerCase().indexOf(query.toLowerCase()) !== -1
          : searchBy1 === "team" ? ("" + item.team).toLowerCase().indexOf(query.toLowerCase()) !== -1
            : searchBy1 === "site" ? ("" + item.assigned_sites).toLowerCase().indexOf(query.toLowerCase()) !== -1
              : null
    });

    // if(query.length === 0){
    // setFilteredList(dummyFilteredList);
    // }else 


    // setFilteredList(searchList.concat(sitesList));

    setDummyFilteredData1(searchList.concat(sitesList))

    setEngineONChecked(false)
    setEngineOFFChecked(false)
    setIdleChecked(false)
    setEngineALLChecked(false)

    // sitesList.map((item)=>{
    //   console.log( "site_name:"+item.site_name  )
    // })

  }


  const handleSearchChange2 = (event, chk, searchBySet) => {
    console.log("event:" + event + " bool:" + chk + "searchBy:" + searchBySet)

    const query = chk ? event : event.target.value
    const searchBy1 = searchBySet === null ? searchBy : searchBySet
    setSearchQuery(query);



    const searchList = dummyFilteredList.filter((item) => {
      return searchBy1 === "employee" ? ("" + item.driver_name).toLowerCase().indexOf(query.toLowerCase()) !== -1
        : searchBy1 === "department" ? ("" + item.hr_department).toLowerCase().indexOf(query.toLowerCase()) !== -1
          : searchBy1 === "team" ? ("" + item.team).toLowerCase().indexOf(query.toLowerCase()) !== -1
            : searchBy1 === "site" ? ("" + item.assigned_sites).toLowerCase().indexOf(query.toLowerCase()) !== -1
              : null
    });


    setFilteredList(searchList.concat(sitesList));

    setEngineONChecked(false)
    setEngineOFFChecked(false)
    setIdleChecked(false)
    setEngineALLChecked(false)


  }
  const selectAfter = (
    <Select defaultValue="employee"
      onChange={(event) => handleSearchBy(event)}
      className="select-after">
      <Option value="employee">employee</Option>
      <Option value="department">department</Option>
      <Option value="team">team</Option>
      <Option value="site">site</Option>
    </Select>
  );
  const handleSearchBy = (event) => {
    setSearchBy(event);
    console.log("okkokkkk" + event)
    handleSearchChange2(searchQuery, true, event)
  };




  const handleAssignedSiteFilter = (event) => {

    if (event === "Yes") {
      setAssignedType("Yes")
    } else if (event === "No") {
      setAssignedType("No")
    } else if (event === "All") {
      setAssignedType("All")
    }
    setSearchQuery("")
    // setFilteredList(AssignedSiteFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredData1(AssignedSiteFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredList(AssignedSiteFiltered(event, data, assignedType, movementType, insideType, attendanceType))

    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])

  }


  const handleWithMovement = (event) => {

    if (event === "Yes") {
      setMovementType("Yes")
    } else if (event === "No") {
      setMovementType("No")
    } else if (event === "All") {
      setMovementType("All")
    }
    setSearchQuery("")

    // setFilteredList(handleMovementFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredData1(handleMovementFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredList(handleMovementFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])
  };


  const handleInsideRangeYes = (event) => {

    if (event === "Yes") {
      setInsideRangeType("Yes")
    } else if (event === "No") {
      setInsideRangeType("No")
    } else if (event === "All") {
      setInsideRangeType("All")
    }
    setSearchQuery("")
    // setFilteredList(handleInsideRangeFiltered(event, data, assignedType, movementType, insideType, attendanceType))

    setDummyFilteredData1(handleInsideRangeFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredList(handleInsideRangeFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])
  };


  const handleOnDuty = (event) => {

    if (event === "Yes") {
      setAttendanceType("Yes")
    } else if (event === "No") {
      setAttendanceType("No")
    } else if (event === "All") {
      setAttendanceType("All")
    }
    setSearchQuery("")
    // setFilteredList(handleOnDutyFiltered(event, data, assignedType, movementType, insideType, attendanceType))

    setDummyFilteredData1(handleOnDutyFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setDummyFilteredList(handleOnDutyFiltered(event, data, assignedType, movementType, insideType, attendanceType))
    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])
  };



  const handleEngineON = (event) => {

    if (event === "Yes") {
      setEngineStatusType("Yes")
    } else if (event === "No") {
      setEngineStatusType("No")
    } else if (event === "All") {
      setEngineStatusType("All")
    }
    setSearchQuery("")

    // setFilteredList(EngineStatusFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineIdleType))
    setDummyFilteredData1(EngineStatusFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineIdleType))
    setDummyFilteredList(EngineStatusFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineIdleType))
    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])
    // setFilteredList(filteredList.filter(item => item.engine_status === 'ON'))

  };


  const handleEngineIdleYes = (event) => {

    if (event === "Yes") {
      setIdleType("Yes")
    } else if (event === "No") {
      setIdleType("No")
    } else if (event === "All") {
      setIdleType("All")
    }
    setSearchQuery("")
    // setFilteredList(EngineIdleFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineType))
    setDummyFilteredData1(EngineIdleFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineType))
    setDummyFilteredList(EngineIdleFiltered(event, data, assignedType, movementType, insideType, attendanceType, engineType))
    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])
    // setFilteredList(filteredList.filter(item => parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0))
  };


  const handleALL = () => {

    setAssignedType("All")
    setMovementType("All")
    setInsideRangeType("All")
    setAttendanceType("All")
    setSearchQuery("")

    // setFilteredList(data.concat(sitesList))
    setDummyFilteredData1(data.concat(sitesList))
    setDummyFilteredList(data)

    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])

  };

  const handleEquimentALL = () => {

    setEngineStatusType("All")
    setIdleType("All")
    setSearchQuery("")

    // setFilteredList(data.filter(item => item))

    setDummyFilteredData1(data.filter(item => item))
    setDummyFilteredList(data.filter(item => item))

    setSiteSelectionList([])
    setJobSelectionList([])
    setDepartmentSelectionList([])

  };

  const handleNameSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleNameSort(nameSort, dummyFilteredData1))
  }

  const handleInsideRangeSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleInsideSort(nameSort, dummyFilteredData1))
  }

  const handleMovementsSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleMovementSort(nameSort, dummyFilteredData1))
  }

  const handleIdleTimeSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleIdleTimeSort(nameSort, dummyFilteredData1))
  }


  const handleDepartmentSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleDepartmentSort(nameSort, dummyFilteredData1))
  }

  const handleEngineStatusSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleEngineStatusSort(nameSort, dummyFilteredData1))
  }

  const handleJobSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleJobSort(nameSort, dummyFilteredData1))
  }

  const handleKMsChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleKMsSort(nameSort, dummyFilteredData1))
  }

  const handleAccommodationSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleAccommodationSort(nameSort, dummyFilteredData1))
  }

  const handleTeamSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleTeamSort(nameSort, dummyFilteredData1))
  }

  const handleAssignedSitesSortChange = () => {
    setNameSort(nameSort + 1)
    setDummyFilteredData1(handleAssignedSitesSort(nameSort, dummyFilteredData1))
  }


  const onSiteSelect = (event) => {
    setSiteSelectionList(event)
    var fillArr = siteSelectionFilter(data, assignedType, movementType, insideType, attendanceType)
    var newArr = [];
    if (event.length <= 0) {
      setFilteredList(fillArr)
      setDummyFilteredList(fillArr)
      newArr = fillArr;
    } else {
      event.map((item1) => {
        newArr = newArr.concat(fillArr.filter(item => item.assigned_sites === item1.assigned_sites))
        // console.log(""+JSON.stringify(newArr))
      })
    }

    console.log("newArr.length---------" + newArr.length)
    if (newArr.length === 0) {
      newArr = fillArr;
      console.log("-----------" + JSON.stringify(newArr))
    }
    var depArr = [];
    console.log("departmentSelectionList.length" + departmentSelectionList.length)
    if (departmentSelectionList.length > 0) {
      departmentSelectionList.map((item1) => {
        depArr = depArr.concat(newArr.filter(item => item.hr_department === item1.hr_department))
      })

    }
    if (depArr.length === 0) {
      depArr = newArr;
    }

    console.log("jobSelectionList.length" + jobSelectionList.length == 0 ? jobSelectionList.length + " true" : jobSelectionList.length + " false")
    var jobArr = [];
    if (jobSelectionList.length > 0) {
      jobSelectionList.map((item1) => {
        jobArr = jobArr.concat(depArr.filter(item => item.hr_job === item1.hr_job))
      })
    }

    jobArr = depArr;

    console.log("jobArr.length" + jobArr.length)
    if (jobArr.length > 0) {
      setFilteredList(jobArr)
      setDummyFilteredList(jobArr)
    }
  }

  const onDepartmentSelect = (event) => {
    setDepartmentSelectionList(event)
    var fillArr = siteSelectionFilter(data, assignedType, movementType, insideType, attendanceType)
    var newArr = [];
    if (siteSelectionList.length <= 0) {
      setFilteredList(fillArr)
      setDummyFilteredList(fillArr)
      newArr = fillArr;
    } else {
      siteSelectionList.map((item1) => {
        newArr = newArr.concat(fillArr.filter(item => item.assigned_sites === item1.assigned_sites))
        // console.log(""+JSON.stringify(newArr))
      })
    }

    console.log("newArr.length---------" + newArr.length)
    if (newArr.length <= 0) {
      newArr = fillArr;
      console.log("-----------" + JSON.stringify(newArr))
    }
    var depArr = [];
    console.log("departmentSelectionList.length" + departmentSelectionList.length)
    if (event.length > 0) {
      event.map((item1) => {
        depArr = depArr.concat(newArr.filter(item => item.hr_department === item1.hr_department))
      })

    }
    if (depArr.length <= 0) {
      depArr = newArr;
    }

    console.log("jobSelectionList.length" + jobSelectionList.length)
    var jobArr = [];
    if (jobSelectionList.length > 0) {
      jobSelectionList.map((item1) => {
        jobArr = jobArr.concat(depArr.filter(item => item.hr_job === item1.hr_job))
      })
    }

    jobArr = depArr;
    console.log("jobArr.length" + jobArr.length)
    if (jobArr.length > 0) {
      setFilteredList(jobArr)
      setDummyFilteredList(jobArr)
    }
  }

  const onJobSelect = (event) => {
    setJobSelectionList(event)
    var fillArr = siteSelectionFilter(data, assignedType, movementType, insideType, attendanceType)
    var newArr = [];
    if (siteSelectionList.length <= 0) {
      setFilteredList(fillArr)
      setDummyFilteredList(fillArr)
      newArr = fillArr;
    } else {
      siteSelectionList.map((item1) => {
        newArr = newArr.concat(fillArr.filter(item => item.assigned_sites === item1.assigned_sites))
        // console.log(""+JSON.stringify(newArr))
      })
    }

    console.log("newArr.length---------" + newArr.length)
    if (newArr.length <= 0) {
      newArr = fillArr;
      console.log("-----------" + JSON.stringify(newArr))
    }
    var depArr = [];
    console.log("departmentSelectionList.length" + departmentSelectionList.length)
    if (departmentSelectionList.length > 0) {
      departmentSelectionList.map((item1) => {
        depArr = depArr.concat(newArr.filter(item => item.hr_department === item1.hr_department))
      })

    }
    if (depArr.length <= 0) {
      depArr = newArr;
    }

    console.log("jobSelectionList.length" + jobSelectionList.length)
    var jobArr = [];
    if (event.length > 0) {
      event.map((item1) => {
        jobArr = jobArr.concat(depArr.filter(item => item.hr_job === item1.hr_job))
      })
    }

    //  jobArr=depArr;
    console.log("jobArr.length" + jobArr.length)
    if (jobArr.length > 0) {
      setFilteredList(jobArr)
      setDummyFilteredList(jobArr)
    } else {
      setFilteredList(depArr)
      setDummyFilteredList(depArr)
    }
  }

  const filterBoth = () => {
    return (
      <div>
        {EquimentFilters()}
        {HumanResourceFilters2()}
        {/* {filterEngineMenu()} */}
        {/* {filterHumanResourceMenu()} */}
      </div>
    )
  }

  const EquimentFilters = () => {
    return (
      <Flex flexDirection="row" >

        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>
          <Box style={{ margin: 5 }}>
            {/* <Text >Engine Status</Text> */}
            {EngineStatusType()}
          </Box>
          <Box style={{ margin: 5 }}>
            <DatePicker
              allowClear={false}
              onChange={onDateChange}
              defaultValue={moment(date, dateFormat)} format={dateFormat}
              style={{ width: '100%', height: 40 }}
            />
          </Box>
        </Flex>

        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>
          {/* IDLE */}
          <Box style={{ margin: 5 }}>
            {/* <Text >Engine IDLE</Text> */}
            {IdleType()}
          </Box>
          <Box style={{ margin: 5 }}>
            {SelectType()}
          </Box>
        </Flex>
        <Box>
          <Button
            style={{ borderRadius: 1, color: tssTheme.COLORS.RED }}
            type="link"
            onClick={() => handleEquimentALL()}
          >Clear</Button>
        </Box>
        <Center style={{ marginTop: 10 }}>
          {isFilterLoading ?
            <Center bg='white.500'> <Spin /> </Center> : null}
        </Center>
      </Flex>
    )
  }


  const HumanResourceFilters2 = () => {
    return (
      <Flex flexDirection="row" >

        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>
          <Box style={{ margin: 5 }}>
            {/* <Text >Assignment</Text> */}
            {AssignmentType()}
          </Box>
          <Box style={{ margin: 5 }}>
            <Multiselect
              options={sitesList} // Options to display in the dropdown
              selectedValues={siteSelectionList} // Preselected value to persist in dropdown
              onSelect={onSiteSelect} // Function will trigger on select event
              onRemove={onSiteSelect} // Function will trigger on remove event
              displayValue="assigned_sites" // Property name to display in the dropdown options
              placeholder={"Select Site"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
            />
          </Box>
          <Box style={{ margin: 5 }}>
            <DatePicker
              allowClear={false}
              onChange={onDateChange}
              defaultValue={moment(date, dateFormat)} format={dateFormat}
              style={{ width: '100%', height: 40 }}
            />
          </Box>
        </Flex>

        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>

          {/* Movement */}
          <Box style={{ margin: 5 }}>
            {/* <Text >Movement</Text> */}
            {MovementType()}
          </Box>
          <Box style={{ width: 160, margin: 5 }}>
            <Multiselect
              options={departmentList} // Options to display in the dropdown
              selectedValues={departmentSelectionList} // Preselected value to persist in dropdown
              onSelect={onDepartmentSelect} // Function will trigger on select event
              onRemove={onDepartmentSelect} // Function will trigger on remove event
              displayValue="hr_department" // Property name to display in the dropdown options
              placeholder={"Select Department"}
              showCheckbox={true}
              style={{ color: '#000' }}
              avoidHighlightFirstOption={false}
            // showArrow={true}
            />
          </Box>
          <Box style={{ margin: 5 }}>
            {SelectType()}
          </Box>
        </Flex>

        {/* 3rd */}
        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>
          {/* Inside */}
          <Box style={{ margin: 5 }}>
            {/* <Text >Inside Range</Text> */}
            {InsideRangeType()}
          </Box>
          <Box style={{ width: 160, margin: 5 }}>
            <Multiselect
              options={jobList} // Options to display in the dropdown
              selectedValues={jobSelectionList} // Preselected value to persist in dropdown
              onSelect={onJobSelect} // Function will trigger on select event
              onRemove={onJobSelect} // Function will trigger on remove event
              placeholder={"Select Job"}
              showCheckbox={true}
              style={{ color: '#000' }}
              displayValue="hr_job" // Property name to display in the dropdown options
              avoidHighlightFirstOption={false}
              showArrow={true}
            />
          </Box>
          <Box>
            <Button
              style={{ borderRadius: 1, color: tssTheme.COLORS.RED }}
              type="link"
              onClick={() => handleALL()}
            >Clear</Button>
          </Box>
        </Flex>

        {/* 4th */}
        <Flex flexDirection="column" color='white' style={{ marginBottom: 2 }}>
          {/* Attendance */}
          <Box style={{ margin: 5 }}>
            {/* <Text >Attendance</Text> */}
            {AttendanceType()}
          </Box>
          <Center style={{ marginTop: 10 }}>
            {isFilterLoading ?
              <Center bg='white.500'> <Spin /> </Center> : null}
          </Center>
        </Flex>

      </Flex>
    )
  }

  const errorMessageApi = (msg) => {
    messageApi.open({
      type: 'error',
      content: 'Message:' + msg,
    });
  };

  const successMessageApi = () => {
    messageApi.open({
      type: 'success',
      content: 'Data Loaded Successfully.',
    });
  };

  const SnackbarView = () => {
    return (
      <Snackbar open={SnackbarShow} autoHideDuration={3000} >
        <div style={{ backgroundColor: tssTheme.COLORS.BLACK, color: tssTheme.COLORS.WHITE, padding: 20, borderRadius: 5 }}>
          <Text style={{ color: Theme.COLORS.WHITE }}> Failed to Load Data. </Text>
          <Text style={{ color: Theme.COLORS.RED }}> {"" + SnackbarMsg}! </Text >
        </div>
      </Snackbar>
    )
  }

  const HeaderIcons = () => {
    return (
      <Flex color='white'>
        <Center flex='1' bg='green.500'>

        </Center>
        <Square flex='1' bg='blue.500' >

        </Square>
        <Box >
          <Center w='40px' h='40px' bg={tssTheme.COLORS.PRIMARY} color='white'
            onClick={() => {
              filter(type, date);
            }}
          >
            <Refresh />
          </Center>
        </Box>
      </Flex>
    )
  }

  const ModelLoading = () => {
    return (
      /* This is the modal that is hidden by default */
      <div style={{ display: isFilterLoading ? 'flex' : 'none', alignSelf: 'center' }} className='modal'>
        <Center style={{ backgroundColor: tssTheme.COLORS.WHITE, padding: 20 }}>
          <div className='loader'></div>
          <div className='modal-text'>Loading...</div>
        </Center>
      </div>
    )
  }




  const ModelView = () => {
    return (
      <div>

        <Modal show={viewItemShow} onHide={handleItemClose} style={{}}>

          {/* <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header> */}

          <Modal.Body>
            {/* <Divider orientation="left" plain>
              Human Resource
            </Divider> */}


            {viewModelItem.tracking_type === "Human Resource" ?
              ResourceView(viewModelItem) : viewModelItem.tracking_type === "Equipment" ? EquipmentView(viewModelItem) : null
            }

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleItemClose}>
              Close
            </Button>

            <Button variant="secondary" onClick={() => {
              dispatch(actionCreators.screenStates("mapping"))
              setDataProp(viewModelItem)
              setResourceHistoryId(viewModelItem.resource_history_id)
              setDateTime(date)
            }}>
              Direction
            </Button>

          </Modal.Footer>

        </Modal>

      </div>
    )
  }


  const TabViewBar = () => {
    return (
      <Tabs
        defaultActiveKey="Human Resource"
        transition={true}
        id="noanim-tab-example"
        className="mb-3 text-primary"
        onSelect={handleActiveKey}
        style={{}}
      >
        <Tab eventKey="Human Resource"
          title={<span style={{ alignSelf: 'center' }}>
            <Person sx={{ fontSize: 20, marginRight: 1 }} />Human Resource </span>
          }
          style={{ backgroundColor: '#000' }}

        >
          {searchBarView2()}
        </Tab>
        <Tab eventKey="Equipment"
          title={<span style={{ alignSelf: 'center' }}>
            <AirportShuttle sx={{ fontSize: 20, marginRight: 1 }} />Equipment </span>
          }
          style={{ alignContent: 'center' }}
        >
          {searchBarView3()}
        </Tab>

        <Tab eventKey="Both"
          title={<span style={{ alignSelf: 'center' }}>
            <Workspaces sx={{ fontSize: 20, marginRight: 1 }} />All</span>
          }
          style={{ alignContent: 'center' }}
        >
          {searchBarView4()}
        </Tab>


        {/* <Tab eventKey="map" title="Map" >
  </Tab>  */}
      </Tabs>
    )
  }


  const searchBarView4 = () => {
    return (
      <Center flex='1' style={{
        backgroundColor: tssTheme.COLORS.PRIMARY,
        padding: 20, borderRadius: 1
      }}>
        {/* <Text >Assignment</Text> */}

        <div style={{ height: 50, }}>
          <DatePicker
            allowClear={false}
            onChange={onDateChange}
            defaultValue={moment(date, dateFormat)} format={dateFormat}
            style={{ height: 50, marginLeft: 10, borderRadius: 2 }}
          />
        </div>
        <div style={{ marginRight: 10, height: 50, }}>
          <Input
            className="header-search"
            placeholder="Search here"
            allowClear
            onChange={handleSearchChange}
            value={searchQuery}
            style={{
              width: '100%',
              borderRadius: 2, marginLeft: 10,
              color: tssTheme.COLORS.BLACK, height: 40
            }}
            prefix={<SearchOutlined />}
            addonAfter={selectAfter}
          />
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {EngineStatusType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {IdleType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {AssignmentType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {MovementType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {InsideRangeType()}
        </div>

        {/* <div style={{ marginLeft: 10, }}>
          <Button onClick={() => { setActiveMarker(null) }}
            style={{
              backgroundColor: tssTheme.COLORS.RED, width: 150, height: 50, color: tssTheme.COLORS.WHITE,
              borderColor: '#D3D3D3', borderRadius: 8, marginTop: 0
            }}
          >
            {"Search"}
          </Button>
        </div> */}

        <Center style={{ marginTop: 10, marginLeft: 10 }}>
          {isFilterLoading ?
            <Center bg='white.500'> <Spin /> </Center> : null}
        </Center>

        {/* <Text style={{}} ><Text strong style={{ color: Theme.COLORS.WHITE }}>{filteredList.length}{" / "}{dummyFilteredList.length}</Text></Text> */}

      </Center>
    )
  }



  const searchBarView3 = () => {
    return (
      <Center flex='1' style={{
        backgroundColor: tssTheme.COLORS.PRIMARY,
        padding: 20, borderRadius: 1
      }}>
        {/* <Text >Assignment</Text> */}

        <DatePicker
          allowClear={false}
          onChange={onDateChange}
          defaultValue={moment(date, dateFormat)} format={dateFormat}
          style={{ height: 50, marginLeft: 10, borderRadius: 2 }}
        />

        <div style={{ marginRight: 10, height: 50, }}>
          <Input
            className="header-search"
            placeholder="Search here"
            allowClear
            onChange={handleSearchChange}
            value={searchQuery}
            style={{
              width: '100%',
              borderRadius: 2, marginLeft: 10,
              color: tssTheme.COLORS.BLACK, height: 40
            }}
            prefix={<SearchOutlined />}
            addonAfter={selectAfter}
          />
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {EngineStatusType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {IdleType()}
        </div>

        {/* <div style={{ marginLeft: 10, }}>
          <Button onClick={() => { setActiveMarker(null) }}
            style={{
              backgroundColor: tssTheme.COLORS.RED, width: 150, height: 50, color: tssTheme.COLORS.WHITE,
              borderColor: '#D3D3D3', borderRadius: 8, marginTop: 0
            }}
          >
            {"Search"}
          </Button>
        </div> */}


        <Center style={{ marginTop: 10, marginLeft: 10 }}>
          {isFilterLoading ?
            <Center bg='white.500'> <Spin /> </Center> : null}
        </Center>

        {/* <Text style={{}} ><Text strong style={{ color: Theme.COLORS.WHITE }}>{filteredList.length}{" / "}{dummyFilteredList.length}</Text></Text> */}

      </Center>
    )
  }

  const searchBarView2 = () => {
    return (
      <Center flex='1' style={{
        backgroundColor: tssTheme.COLORS.PRIMARY,
        padding: 20, borderRadius: 1
      }}>
        {/* <Text >Assignment</Text> */}

        <DatePicker
          allowClear={false}
          onChange={onDateChange}
          defaultValue={moment(date, dateFormat)} format={dateFormat}
          style={{ height: 50, marginLeft: 10 }}
        />
        {/* <div style={{ marginLeft: 10,height:50 }}>
        {SelectType()}
        </div> */}

        <div style={{ marginRight: 10, height: 50, }}>
          <Input
            className="header-search"
            placeholder="Search here"
            allowClear
            onChange={handleSearchChange}
            value={searchQuery}
            style={{
              width: '100%',
              borderRadius: 8, marginLeft: 10,
              color: tssTheme.COLORS.BLACK, height: 40
            }}
            prefix={<SearchOutlined />}
            addonAfter={selectAfter}
          />
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {AssignmentType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {MovementType()}
        </div>

        <div style={{ marginLeft: 10, height: 50 }}>
          {InsideRangeType()}
        </div>

        {/* <div style={{ marginLeft: 10, height: 50 }}>
            <Multiselect
              options={sitesList} // Options to display in the dropdown
              selectedValues={siteSelectionList} // Preselected value to persist in dropdown
              onSelect={onSiteSelect} // Function will trigger on select event
              onRemove={onSiteSelect} // Function will trigger on remove event
              displayValue="assigned_sites" // Property name to display in the dropdown options
              placeholder={"Select Site"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
            />
          </div> */}

        <Center style={{ marginTop: 10, marginLeft: 10 }}>
          {isFilterLoading ?
            <Center bg='white.500'> <Spin /> </Center> : null}
        </Center>


        {/* <Text style={{}} ><Text strong style={{ color: Theme.COLORS.WHITE }}>{filteredList.length}{" / "}{dummyFilteredList.length}</Text></Text> */}


      </Center>
    )
  }



  const vehicleMapView = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => setActiveMarker(null)}
      >
        { /* 
     data.filter(type=>type.tracking_type.includes(""+type)).map((place, i) => {
      Child components, such as markers, info windows, etc.
       */ }
        {
          filteredList &&
          filteredList.filter(data => type === "Human Resource" ? driver.length > 0 ? data.driver_name === "" + driver : !!data : team.length > 0 ? data.team_name === "" + team : !!data).map((place) => {

            let lat = parseFloat(place.current_latitude, 10);
            let lng = parseFloat(place.current_longitude, 10);
            const icon1 = {
              url: place.tracking_type === "Equipment" ? Theme.URL.carIcon :
                place.tracking_type === "Human Resource" ?
                  "" + Theme.URL.standing_up_manIcon : Theme.URL.siteIcon
              ,
              scaledSize: new window.google.maps.Size(30, 30),
              // anchor: { x: 10, y: 10 },
              anchor: new window.google.maps.Point(15, 15),
              scale: 0.7
            };

            return (
              <Marker
                id={place.resource_history_id}
                key={place.resource_history_id}
                position={{ lat: lat, lng: lng }}
                title={place.tracking_type === "site" ? place.site + " / " + place.site_name : "" + place.driver_name}
                icon={icon1}
                onClick={() => {
                  setVehicleDetail(place)
                  // place.tracking_type === "site"?handleActiveMarker(place.site):
                  handleActiveMarker(place.resource_history_id)
                }
                }
              >

                {

                  activeMarker === place.resource_history_id ? (
                    console.log(place.resource_history_id),
                    // null
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>

                        {mapVehicleDetailView()}
                      </div>
                    </InfoWindow>
                  ) : (
                    //   activeMarker === place.site ? (
                    //  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    //  <div>
                    //  {  mapVehicleDetailView()  }
                    // </div>
                    //   </InfoWindow>,
                    // console.log('Sites:'+place.site)):
                    null)
                }
              </Marker>
            );
          })}
      </GoogleMap>
    )
  }

  const vehicleEngineDataBoxView = (vehicleDetail) => {
    return (

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={10.4}>

          <Box>
            <Flex>
              <Flex flexDirection='column'>
                <AirportShuttle sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                {/* <Text strong  style={{color:Theme.COLORS.BLACK}}> Fleet</Text> */}
              </Flex>
              <Text >  : {vehicleDetail.fleet_name}</Text>
            </Flex>


            <Box>
              {vehicleDetail.hr_department ?
                <Box>
                  <Domain sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                  <Text onMouseOver={() => <h3>Hello World</h3>}
                    onMouseOut={() => { }}
                  >
                    : {"" + vehicleDetail.hr_department}
                  </Text>
                </Box>
                : null}
            </Box>

            <Box>
              {vehicleDetail.hr_job ?
                <Box>
                  <Work sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                  <Text onMouseOver={() => <h3>Hello World</h3>}
                    onMouseOut={() => { }}
                  >
                    : {"" + vehicleDetail.hr_job}
                  </Text>
                </Box>
                : null}
            </Box>

            <Box>
              {vehicleDetail.team ?
                <Box>
                  <Diversity1 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                  <Text onMouseOver={() => <h3>Hello World</h3>}
                    onMouseOut={() => { }}
                  >  : {"" + vehicleDetail.team}</Text>
                </Box>
                : null
              }
            </Box>


            <Box>
              <Avatar src={<Image src={kilometer}
                style={{}} />}
                size={25}
              />
              <Text > : {parseFloat("" + vehicleDetail.today_km).toFixed(2)}  (today)</Text>
            </Box>
            <Box>
              {/* <Speed  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }}/> */}
              <Avatar src={<Image src={Odometer}
                style={{}} />}
                size={25}
              />
              <Text > : {parseFloat("" + vehicleDetail.end_odometer).toFixed(2)}</Text>
            </Box>

            {/* <Box>
     <MoreTime  sx={{ fontSize: 25,color:tssTheme.COLORS.BLACK }} />
     <Text onMouseOver={()=><h3>Hello World</h3>}
           onMouseOut={() => {}}
      > : {parseFloat(""+vehicleDetail.engine_on_since_when).toFixed(2)}</Text>

    </Box>  */}

            {/* <Box>
     <BatteryUnknown  sx={{ fontSize: 25,color:tssTheme.COLORS.BLACK }} />
     <Text > : {""+vehicleDetail.trip_state}</Text>
    </Box>  */}

            {/* <Box>
       <RunCircle  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} />
       <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.movements_count} Movements </Text>
    </Box> */}


            {/* <Box>
       <LocationOn  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} />
       <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.current_latitude}" , {vehicleDetail.current_longitude}"</Text>
    </Box> */}

          </Box>
        </Grid>


        <Grid item xs={1.6} spacing={1}>
          <Tooltip title="Engine Status">
            <Grid item >

              <ButtonGroup gap='2'>
                <Stack spacing={1}>
                  <Button style={{
                    width: 10, height: 10, borderRadius: 15, fontSize: 1
                    , backgroundColor: vehicleDetail.engine_status === 'OFF' ? tssTheme.COLORS.SECONDARY : tssTheme.COLORS.forestgreen,
                  }} >.</Button>
                  <Text strong style={{ color: Theme.COLORS.BLACK, fontSize: 10, textAlign: 'center' }} >{vehicleDetail.tracking_type === "Human Resource" ? "" : vehicleDetail.engine_status}</Text>
                </Stack>
              </ButtonGroup>

            </Grid>
          </Tooltip>

          <Tooltip title="Total Idle Time">
            <Grid item >
              <div style={{
                width: 30, height: 25, backgroundColor: tssTheme.COLORS.ICON,
                textAlign: 'center', marginTop: 10
              }}>

                <Text style={{
                  backgroundColor: tssTheme.COLORS.ICON,
                  textAlign: 'center', fontSize: 12, fontWeight: 'bold',
                  color: tssTheme.COLORS.WHITE
                }}>
                  {parseFloat("" + vehicleDetail.engine_idle_time_with_no_movement).toFixed(1)}
                </Text>
              </div>
            </Grid>
          </Tooltip>

        </Grid>


      </Grid>

    )
  }




  const GridHumanResourceHeader = (vehicleDetail) => {
    return (
      <Flex color='white' gap='2'>
        {/* <Box w='50px' bg='green.500'>
          <Flex>
          <Avatar src={<Image 
          src="https://bit.ly/dan-abramov" 
                              style={{  }} />} 
                              size={40}
          />
          </Flex>
        </Box> */}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={10.5}>
            <Tooltip title="">
              <Text
                // onMouseOver={MouseOver} 
                // onMouseOut={MouseOut} 
                strong style={{ color: tssTheme.COLORS.PRIMARY }}>{vehicleDetail.driver_name}
              </Text>
            </Tooltip>
          </Grid>

          {/* <Grid item xs={1}>
        <Box style={{width:10,height:15,fontSize:1,
                            fontSize:5,borderColor:tssTheme.COLORS.BLACK,borderWidth:2,
                          backgroundColor:vehicleDetail.inside_range < 1 ?tssTheme.COLORS.BLACK:tssTheme.COLORS.forestgreen,}} >
          </Box>
          </Grid> */}

          <Grid item xs={1.5}>
            <Link
              to={{
                // pathname: '/mapping',
                // // search: '?sort=name',
                // // hash: '#the-hash',
                // id:vehicleDetail.resource_history_id,
                // place:vehicleDetail,
                // params: { id: 88 },
                // datetime:date
              }}
            >
              <Tooltip title="Live Track">
                <MyLocation
                  onClick={() => {
                    dispatch(actionCreators.screenStates("mapping"))
                    setDataProp(vehicleDetail)
                    setResourceHistoryId(vehicleDetail.resource_history_id)
                    setDateTime(date)
                  }}
                  sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY, }}
                />
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
        {/* <Box p='2'>
            <ButtonGroup gap='2'>
              <Stack spacing={2}>
                
              <Box style={{width:10,height:15,fontSize:1,
                            fontSize:5,borderColor:tssTheme.COLORS.BLACK,borderWidth:2,
                          backgroundColor:vehicleDetail.inside_range < 1 ?tssTheme.COLORS.BLACK:tssTheme.COLORS.forestgreen,}} >
          </Box>
           </Stack>
            </ButtonGroup>
        </Box> */}
      </Flex>
    )
  }



  const LoadingSpinner = () => {
    return <div style={{
      width: '100%',
      height: '100%',
    }}>
      <div style={{ flexDirection: 'row' }}>
        {TabViewBar()}
      </div>
      <Center flex="1" style={{ marginTop: 10, height: 500 }}>
        <Flex flexDirection="row" >
          <div style={{ alignSelf: 'center' }}>
            <OrbitSpinner size={120} style={{}} color={tssTheme.COLORS.BLACK} />
            <Text style={{ textAlign: 'center' }} strong>Loading...</Text>
          </div>
        </Flex>
        <br />

      </Center>


    </div>
  }

  const EquipmentView = (vehicleDetail) => {
    return (
      <Card
        style={{
          borderColor: vehicleDetail.engine_status === 'OFF' ? tssTheme.COLORS.BLACK : parseFloat("" + vehicleDetail.engine_on_since_when).toFixed(2) >= 10.0 ? tssTheme.COLORS.RED :
            tssTheme.COLORS.forestgreen, borderWidth: 2
        }} >
        {GridHumanResourceHeader(vehicleDetail)}
        <Stack spacing={3} style={{ marginTop: 10, }}>
          {vehicleEngineDataBoxView(vehicleDetail)}
          {/* {pathNavigationBtn(vehicleDetail)}   */}
          {/* {Bottom(vehicleDetail)} */}
        </Stack>
      </Card>
    )
  }



  const ResourceView = (vehicleDetail) => {
    return (
      <Card
        style={{
          borderColor: vehicleDetail.engine_status === 'OFF' ? tssTheme.COLORS.PRIMARY : parseFloat("" + vehicleDetail.engine_on_since_when).toFixed(2) >= 10.0 ? tssTheme.COLORS.RED :
            tssTheme.COLORS.forestgreen, borderWidth: 2
        }}
      >
        {GridHumanResourceHeader(vehicleDetail)}
        <Stack spacing={3} style={{ marginTop: 10, }}>
          {HumanResourceBoxView(vehicleDetail)}
          {/* {pathNavigationBtn(vehicleDetail)}   */}
        </Stack>
      </Card>
    )
  }


  const HumanResourceBoxView = (vehicleDetail) => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={10.5}>
          <Box>

            <Tooltip title="Department">
              <Box>
                {vehicleDetail.hr_department ?
                  <Box>
                    <Domain sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_department}
                    </Text>
                  </Box>
                  : null}
              </Box>
            </Tooltip>

            <Tooltip title="Job">
              <Box>
                {vehicleDetail.hr_job ?
                  <Box>
                    <Work sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >
                      : {"" + vehicleDetail.hr_job}
                    </Text>
                  </Box>
                  : null}
              </Box>
            </Tooltip>


            <Tooltip title="Team">
              <Box>
                {vehicleDetail.team ?
                  <Box>
                    <Diversity1 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.team}</Text>
                  </Box>
                  : null}
              </Box>
            </Tooltip>

            <Tooltip title="Accommodation">
              <Box>
                {vehicleDetail.employee_accommodation ?
                  <Box>
                    <House sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + vehicleDetail.employee_accommodation}</Text>
                  </Box>
                  : null}
              </Box>
            </Tooltip>


            <Tooltip title="Sites">
              <Box>
                {vehicleDetail.assigned_sites ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + vehicleDetail.assigned_sites}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>
            </Tooltip>


            {/* <Box>
      {vehicleDetail.movements_count?
       <Box>  
       <RunCircle  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} />
       <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.movements_count} Movements </Text>
       </Box>
      : null }
    </Box>  */}

            {/* <Box>
      {vehicleDetail.leave_status?
       <Box>  
       <Info  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} /> */}
            {/* <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.leave_status === "Present" ? "On Duty" : vehicleDetail.leave_status}  </Text> */}
            {/* <OfflinePin  sx={{ fontSize: 25,color:vehicleDetail.inside_range>1?tssTheme.COLORS.forestgreen:tssTheme.COLORS.BLACK, }}  />
      
      {vehicleDetail.leave_status === "Present"?
       <Engineering  sx={{ fontSize: 25,color:tssTheme.COLORS.BLACK, }}  />
       :  <PersonOff  sx={{ fontSize: 25,color:tssTheme.COLORS.RED, }}  />
      }

       </Box>
      : null }
    </Box> */}


            {/* <Box>
      {vehicleDetail.current_latitude?
      <Box>
       <LocationOn  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} />
       <Text strong  style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.current_latitude}" , {vehicleDetail.current_longitude}"</Text>
      </Box>
       : null }
    </Box> */}

          </Box>
        </Grid>


        <Grid item xs={1.5} style={{ backgroundColor: '' }} >

          <Grid container spacing={0.5} style={{ height: '100%', alignSelf: 'center', }}>

            <Tooltip title="Inside Range">
              <Grid item >
                <CellTower sx={{ fontSize: 25, color: vehicleDetail.inside_range > 1 ? tssTheme.COLORS.forestgreen : tssTheme.COLORS.BLACK, }} />
              </Grid>
            </Tooltip>

            <Tooltip title="Attendance">
              <Grid item >
                {vehicleDetail.leave_status === "Present" ?
                  <HowToReg sx={{ fontSize: 25, color: tssTheme.COLORS.forestgreen, }} /> :
                  <Person sx={{ fontSize: 25, color: tssTheme.COLORS.RED, }} />}
              </Grid>
            </Tooltip>

            <Tooltip title="Movement Count">
              <Grid item >
                <Text style={{
                  padding: ("" + vehicleDetail.movements_count).length === 1 ? 7 : 6,
                  backgroundColor: vehicleDetail.inside_range < 1 ? tssTheme.COLORS.BLACK : tssTheme.COLORS.forestgreen,
                  textAlign: 'center', fontSize: 12,
                  color: tssTheme.COLORS.WHITE
                }}>
                  {("" + vehicleDetail.movements_count)}
                </Text>
              </Grid>
            </Tooltip>

          </Grid>
        </Grid>

      </Grid>

    )
  }

  const vehicleGridView = () => {
    const isMobile = width <= 768;

    return (
      <SimpleGrid
        // columns={isMobile?[2,  2]:[2,  4]} 
        columns={isMobile ? [2, 4] : [2, 4]} display="grid" gridGap={10} spacing='40px'>
        {
          // data.filter(item => item.engine_status == 'ON' )
          filteredList.map((vehicleDetail) => {
            return vehicleDetail.tracking_type === "Human Resource" ?
              ResourceView(vehicleDetail) : vehicleDetail.tracking_type === "Equipment" ? EquipmentView(vehicleDetail) : null
          }
          )
        }

      </SimpleGrid>
    )
  }

  const vehicleListView = () => {

    return (
      <table id="employee" style={{ margin: 0 }}
      >
        <tr>
          {/* <th style={{color:tssTheme.COLORS.BLACK,textAlign:'left'}}>ID.</th> */}
          <th onClick={() => { handleNameSortChange() }} >
            Name  <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          {type !== "Both" ?
            <th onClick={() => { type === "Human Resource" ? handleDepartmentSortChange() : handleEngineStatusSortChange() }} >
              {type === "Human Resource" ? "Department" : type === "Equipment" ? "Engine Status" : "Dep / Status"}  <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
            </th>
            : null}

          {type !== "Both" ?
            <th onClick={() => { type === "Human Resource" ? handleJobSortChange() : handleKMsChange() }} >
              {type === "Human Resource" ? "Job" : type === "Equipment" ? "Today's KM" : "Dep / Today's KM"}   <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
            </th>
            : null}


          <th onClick={() => { handleAccommodationSortChange() }} >
            {type === "Human Resource" ? "Accommodation" : type === "Equipment" ? "Fleet" : "Accommodation"}   <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          <th onClick={() => { handleTeamSortChange() }} >
            Team  <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          <th style={{}} onClick={() => { handleAssignedSitesSortChange() }} >
            Assigned Sites  <UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          <th onClick={() => { handleInsideRangeSortChange() }} >
            Inside Range<UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          {type === "Equipment" ?
            <th onClick={() => { handleIdleTimeSortChange() }} >
              Total Idle Time (min)<UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
            </th>
            : null}
          <th onClick={() => { handleMovementsSortChange() }} >
            Today's Movement<UnfoldMore sx={{ fontSize: 15, color: tssTheme.COLORS.WHITE, alignSelf: 'center' }} />
          </th>
          <th > </th>
        </tr>
        {dummyFilteredData1.map((val, key) => {

          return (
            // val.tracking_type === 'site' && val.driver_name ?null:
            val.driver_name ?
              <tr key={key}

                onClick={() => {
                  setModelItem(val)
                  handleItemShow()

                }}>

                {/* <td >{val.driver_id}</td> */}
                <td style={{}}>
                  {type === "Both" && val.tracking_type === "Human Resource" ?
                    <Person sx={{ fontSize: 15, color: tssTheme.COLORS.PRIMARY, alignSelf: 'center', }} />
                    : type === "Both" && val.tracking_type === "Equipment" ?
                      <AirportShuttle sx={{ fontSize: 15, color: tssTheme.COLORS.PRIMARY, alignSelf: 'center' }} />
                      : null
                  }
                  {key + ". "}{val.driver_name}</td>
                {type !== "Both" ?
                  <td >{type === "Human Resource" ? val.hr_department : val.engine_status}</td>
                  : null}
                {type !== "Both" ?
                  <td  >{type === "Human Resource" ? val.hr_job : parseFloat("" + val.today_km).toFixed(2)}</td>
                  : null}
                <td >{type === "Human Resource" ? val.employee_accommodation : type === "Both" ? val.employee_accommodation : val.fleet_name}</td>
                <td >{val.team}</td>
                <td style={{ width: 10 }} >{val.assigned_sites}</td>
                <td >{val.inside_range > 0 ? 'Y' : 'N'} {"(" + parseFloat("" + val.inside_range).toFixed(1) + "%)"}</td>
                {type === "Equipment" ?
                  <td style={{ width: 10 }} >{parseFloat(val.engine_idle_time_with_no_movement).toFixed(2)}</td>
                  : null}
                <td >{val.movements_count}</td>
                <td onClick={() => {
                  dispatch(actionCreators.screenStates("mapping"))
                  setDataProp(val)
                  setResourceHistoryId(val.resource_history_id)
                  setDateTime(date)
                }}>
                  <ArrowOutward
                    sx={{ fontSize: 15, color: tssTheme.COLORS.PRIMARY, alignSelf: 'center' }}
                  />
                </td>
              </tr>
              : null
          )
        })}
      </table>

      /* {   
      // data.filter(item => item.engine_status == 'ON' )
      filteredList.map((vehicleDetail, i)=>{
       return  <Text 
        strong style={{color:tssTheme.COLORS.PRIMARY}}>{vehicleDetail.driver_name}
       </Text>   
       }
        )
      } */

    )
  }

  const filter = (type, dateString) => {
    try {
      setFilterLoading(true)
      setDataLoaded(true)
      fetch('' + tssTheme.URL.search_vehicle_tracking_information + "?resource_date=" + dateString + "&tracking_type=Both", {
        method: 'POST',
        mode: 'cors', // 'cors' by default
        'Access-Control-Allow-Origin': '*',
        body: JSON.stringify({
        }),
        headers: {},
      })
        .then(function (response) {
          if (!response.ok) {
            errorMessageApi("This is an error message")
            return response.text().then(result => Promise.reject(new Error(result)));
          }
          return response.json();
        }).then((responseJson) => {
          //  console.log(responseJson)

          setData(responseJson.result)
          setTeams(responseJson.result)
          setFilteredList(responseJson.result)
          let siteArray = responseJson.result.filter(item => item.tracking_type === 'Human Resource')
          let departmentArray = responseJson.result.filter(item => item.tracking_type === "Human Resource")
          let jobArray = responseJson.result.filter(item => item.tracking_type === "Human Resource")

          //  siteArray = siteArray.filter(function (value, index, array) { 
          //   return array.indexOf(value.site_name) === index;
          // });

          setSitesList(removeSiteDuplicate(siteArray))
          //  removeSiteDuplicate(siteArray)
          setDepartmentList(removeDepartmentDuplicate(departmentArray))
          setJobList(removeJobDuplicate(jobArray))
          dispatch(actionCreators.dataHistory(responseJson.result))

          // setDummyFilteredList(responseJson.result)
          // setListDriver(responseJson.result)

          // setFilterLoading(false)
          // setLoading(true)

          // const human = responseJson.result.filter(item => item.tracking_type === 'Equipment');
          // setHumanCount(human.length)
          // const equipment = responseJson.result.filter(item => item.tracking_type === 'Human Resource');
          // setEquipmentCount(equipment.length)
          // const site = responseJson.result.filter(item => item.tracking_type === 'site');
          // setSitesCount(site.length)
          // successMessageApi("")

          filteringData(type)

        })
        .catch((err) => {

          console.log("errT ", err)
          setFilterLoading(false)
          setLoading(true)
          setSnackbarMsg("Message:" + err)
          setSnackbarShow(true)
          setDataLoaded(false)
          setTimeout(
            () => setSnackbarShow(false),
            3000
          );
        });
    } catch (e) {
      console.log('Error')
      errorMessageApi("This is an error message")
    }
  }


  const filteringData = (type) => {

    var filtertedData = type === "Both" ? filteredList :
      filteredList.filter(item => item.tracking_type === type)

    setDummyFilteredData1(filtertedData)
    setListDriver(filtertedData)

    setFilterLoading(false)
    setLoading(true)

    const human = filtertedData.filter(item => item.tracking_type === 'Equipment');
    setHumanCount(human.length)
    const equipment = filtertedData.filter(item => item.tracking_type === 'Human Resource');
    setEquipmentCount(equipment.length)
    const site = filtertedData.filter(item => item.tracking_type === 'site');
    setSitesCount(site.length)
    successMessageApi("")
    setType_R("Equipment")

    setTimeout(
      () => setDataLoaded(false),
      300
    );
  }


  const fetchData = (type, dateString) => {
    try {
      setFilterLoading(true)
      fetch('' + tssTheme.URL.search_vehicle_tracking_information + "?resource_date=" + dateString + "&tracking_type=" + type, {
        method: 'POST',
        mode: 'cors', // 'cors' by default
        'Access-Control-Allow-Origin': '*',
        body: JSON.stringify({
        }),
        headers: {},
      })
        .then(function (response) {
          if (!response.ok) {
            errorMessageApi("This is an error message")
            return response.text().then(result => Promise.reject(new Error(result)));
          }
          return response.json();
        }).then((responseJson) => {
          //  console.log(responseJson)

          setData(responseJson.result)
          setTeams(responseJson.result)
          setFilteredList(responseJson.result)
          let siteArray = responseJson.result.filter(item => item.tracking_type === 'Human Resource')
          let departmentArray = responseJson.result.filter(item => item.tracking_type === "Human Resource")
          let jobArray = responseJson.result.filter(item => item.tracking_type === "Human Resource")

          //  siteArray = siteArray.filter(function (value, index, array) { 
          //   return array.indexOf(value.site_name) === index;
          // });

          setSitesList(removeSiteDuplicate(siteArray))
          //  removeSiteDuplicate(siteArray)
          setDepartmentList(removeDepartmentDuplicate(departmentArray))
          setJobList(removeJobDuplicate(jobArray))

          dispatch(actionCreators.dataHistory(responseJson.result))

          setDummyFilteredList(responseJson.result)
          setListDriver(responseJson.result)

          setFilterLoading(false)
          setLoading(true)

          const human = responseJson.result.filter(item => item.tracking_type === 'Equipment');
          setHumanCount(human.length)
          const equipment = responseJson.result.filter(item => item.tracking_type === 'Human Resource');
          setEquipmentCount(equipment.length)
          const site = responseJson.result.filter(item => item.tracking_type === 'site');
          setSitesCount(site.length)
          successMessageApi("")
        })
        .catch((err) => {

          console.log("errT ", err)
          setFilterLoading(false)
          setLoading(true)
          setSnackbarMsg("Message:" + err)
          setSnackbarShow(true)
          setTimeout(
            () => setSnackbarShow(false),
            3000
          );
        });
    } catch (e) {
      console.log('Error')
      errorMessageApi("This is an error message")
    }
  }



  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    const bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < data.length; i++) {
      bounds.extend(new window.google.maps.LatLng(data[i].latitude, data[i].longitude));
    }
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  if (screenStates === "mapping")
    return (
      <Flex color='white'>

        <Search place={dataProp}
          id={resource_history_id}
          datetime={datetime}
        />
      </Flex>

    )
  else
    return isLoading ? (
      <div style={{
        width: '100%',
        height: '100%',
      }}>


        {ModelView()}
        {SnackbarView()}

        {contextHolder}
        {ModelLoading()}

        <div color='white'>
          <Box flex='1' >

            {isDataLoaded ? null : HeaderIcons()}

            <div style={{ flexDirection: 'row' }}>
              {TabViewBar()}
            </div>

            {isDataLoaded ? null : viewType === '0' ?
              vehicleMapView()
              : viewType === '1' ? vehicleGridView()
                : vehicleListView()
            }
          </Box>
        </div>

      </div>
    ) : LoadingSpinner()
}

export default React.memo(MyComponent)