const reducer = (state=0, action)=>{
    if(action.type === 'deposit'){
        return state + action.value
    }else if(action.type === 'withdraw'){
        return state - action.value
    }else if(action.type === 'userID'){
        return state+action.value
    }
    else return state;

}

export default reducer;