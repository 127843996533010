import React, { useState, useEffect, Component } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Link, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';


import { ModalHover } from 'react-modal-hover'

import { tssTheme } from '../../constants'
import { UserOutlined } from '@ant-design/icons';
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
// import Button from 'react-bootstrap/Button';
import { Flex, Spacer, Center, Square, Box, SimpleGrid, Stack, ButtonGroup } from '@chakra-ui/react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
} from '@chakra-ui/react'
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
import { fadeIn } from 'react-animations'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
// import {TestApp} from 'mniwnewnpm';


import Theme from '../../constants/Theme';

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/state';
import {
  SwappingSquaresSpinner,
} from 'react-epic-spinners'
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Spin,
  Input, TimePicker,
  DatePicker, Space, Tooltip
} from "antd";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Odometer from '../../assets/images/odometer.png'
import kilometer from '../../assets/images/kilometer.png'

import Grid from '@mui/material/Grid';

import {
  AirportShuttle,
  MoreTime,
  BatteryUnknown,
  LocationOn,
  People,
  Apartment,
  Diversity1,
  Engineering,
  Domain,
  House,
  RunCircle,
  ExitToApp,
  EnergySavingsLeaf,
  Map,
  MyLocation,
  Layers,
  PersonOff,
  Info,
  OfflinePin,
  CellTower,
  HowToReg,
  Person,
  Boy,
  DirectionsCar,
  CarCrash,
  Grid3x3,
  Window,
  Work,
  AccessTime
} from '@mui/icons-material';

const { Text, Title } = Typography;

const options = [
  { label: 'Idle Vehicle', value: 'Apple' },
  { label: 'Engine ON', value: 'Pear' },
  { label: 'Engine OFF', value: 'Orange' },
];
const containerStyle = {
  width: '100%',
  height: 600
};

const center = {
  lat: 25.2048,
  lng: 55.2708
};

const style: React.CSSProperties = { background: '#0092ff', padding: '8px 0' };

export default class BoxView extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
      viewType: '1',
      width: window.innerWidth,
      dateTime: '',
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.data, dateTime: this.props.dateTime })
    console.log(this.props.data.driver_name)
  }



  mapEquipmentDetailView = (dateTime) => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={10.0}>
          <Box>
            <AnimateGroup play>
              <Animate start={{ opacity: 0 }} end={{ opacity: 1 }} sequenceIndex={0}>

                {/* <Flex >
              <Box flex='0.98' >
              </Box>
              <Box  >
                 <Button onClick={()=>{setActiveMarker(null)}} 
                   style={{backgroundColor:'#FFF',color:'#000',borderColor:'#D3D3D3',borderRadius:2,marginTop:0}}
                  >
                 {"Close"}
                 </Button> 
              </Box>
            </Flex> */}

                <Row>
                  <Col>
                    {this.state.data.resource_name ?
                      <Text ><Text strong underline style={{ color: Theme.COLORS.PRIMARY }}>{this.state.data.resource_name}</Text></Text>
                      : null}
                  </Col>
                </Row>

              </Animate>

              <Box>
                {this.state.data.team ?
                  <Box>
                    <Diversity1 sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                    <Text onMouseOver={() => <h3>Hello World</h3>}
                      onMouseOut={() => { }}
                    >  : {"" + this.state.data.team}</Text>
                  </Box>
                  : null
                }
              </Box>


              <Box>
                {this.state.data.assigned_sites ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + this.state.data.assigned_sites}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>


              <Box>
                {this.state.data.customer ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + this.state.data.customer}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>

              <Box>
                {this.state.data.site_model ?
                  <Box>
                    <Box>
                      <Engineering sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text style={{ textAlign: 'center', }}> : {"" + this.state.data.site_model.toUpperCase()}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>



              <Box>
                {this.state.data.recorded_date ?
                  <Box>
                    <Box>
                      <AccessTime sx={{ fontSize: 25, color: tssTheme.COLORS.PRIMARY }} />
                      <Text strong style={{ textAlign: 'center', }}> : {"" + this.state.data.recorded_date}</Text>
                    </Box>
                  </Box>
                  : null}
              </Box>

            </AnimateGroup>

            <Row>
              <Col>
                {this.state.data.tracking_type ?
                  <Text ><Text strong underline style={{ color: Theme.COLORS.PRIMARY }}>{this.state.data.tracking_type}</Text></Text>
                  : null}
              </Col>
            </Row>

          </Box>
        </Grid>

        {/* <Grid item xs={1.2} style={{backgroundColor:''}} >
               
             <Grid container spacing={0.5} style={{height:'100%',alignSelf:'center',}}>
             
              <Grid item >
                <CellTower  sx={{ fontSize: 25,color:this.state.data.inside_range>1?tssTheme.COLORS.forestgreen:tssTheme.COLORS.BLACK, }}  />
              </Grid>
              <Grid item >
              {this.state.data.leave_status === "Present"?
              <HowToReg  sx={{ fontSize: 25,color:tssTheme.COLORS.forestgreen, }}  />:
              <Person  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY, }}  />}
              </Grid>
              <Grid item >
               
                <Text style={{padding:(""+this.state.data.movements_count).length === 1?7:6, 
                backgroundColor:this.state.data.inside_range < 1 ?tssTheme.COLORS.BLACK:tssTheme.COLORS.forestgreen,
                textAlign:'center',fontSize:12,
               color:tssTheme.COLORS.WHITE}}>
                  {(""+this.state.data.movements_count)} 
                </Text>
                              
              </Grid>
            </Grid>  
          </Grid> 
       */}

      </Grid>
    )

  }



  Bottom(vehicleDetail) {
    return (
      <Center style={{ backgroundColor: tssTheme.COLORS.ICON, borderRadius: 4 }}>
        <Text strong style={{ color: '#FFF', textAlign: 'center', }}>TOTAL IDLE TIME: {parseFloat("" + vehicleDetail.engine_idle_time_with_no_movement).toFixed(2)}</Text>
      </Center>
    )
  }

  render() {
    // const  vehicleDetail  = this.props.data;
    return (
      this.state.data ? this.mapEquipmentDetailView(this.state.dateTime) : null
    )
  }

}
