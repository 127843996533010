import React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Map from "./map/Map";
import Geocode from "react-geocode";
import "./map/styles.css";
import {tssTheme} from '../constants'
// import Autocomplete from 'react-google-autocomplete';
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");


const places = [
  {
    name: "Newcastle",
    title: "Newcastle",
    lat: -33.0029954,
    lng: 147.204751,
    id: 1
  },
  {
    name: "Sydney",
    title: "Sydney",
    lat: -33.847927,
    lng: 150.6517938,
    id: 2
  },
  {
    name: "Melbourne",
    title: "Melbourne",
    lat: -37.9722342,
    lng: 144.7729561,
    id: 3
  },
  {
    name: "Perth",
    title: "Perth",
    lat: -31.9546904,
    lng: 115.8350292,
    id: 4
  }
];


class LocationSearchModal extends React.Component {

    state = {
        address: '',
        city: '',
        area: '',
        state: '',
        zoom: 15,
        height: 500,
        mapPosition: {
            lat: 0,
            lng: 0,
        },
        markerPosition: {
            lat: 0,
            lng: 0,
        },
        Building:'',
        pincode:''
    }


    componentDidMount() {
      
    };


    render() {
        return (
            <div>
          
                <Map
        googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+tssTheme.APP.MapAPIKey}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={{ lat: -33.8665433, lng: 151.1956316 }}
        zoom={4}
        places={places}
      />
            </div>
        )
    }

}

export default LocationSearchModal;