import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { tssTheme } from '../../constants'
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import {
  SwappingSquaresSpinner, OrbitSpinner
} from 'react-epic-spinners'
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Spin,
  Input, TimePicker,
  DatePicker, Space, Tooltip, Select
} from "antd";
import {
  SearchOutlined,
  StarOutlined,
  TwitterOutlined,
  FacebookFilled,
  UserOutlined
} from "@ant-design/icons";
import {
  AirportShuttle,
  Person,
  Workspaces,
  AdminPanelSettings,
  FilterAlt
} from '@mui/icons-material';
import './drop-down.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/state';
import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Odometer from '../../assets/images/odometer.png'
import kilometer from '../../assets/images/kilometer.png'

import { Flex, Spacer, Center, Square, Box, SimpleGrid } from '@chakra-ui/react'
import Multiselect from 'multiselect-react-dropdown';
import { useToast } from '@chakra-ui/react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { Animate, AnimateGroup } from "react-simple-animate";
import Theme from '../../constants/Theme';
import {
  removeSiteDuplicate, removeEmpNameDuplicate,
  removeEmpNameDuplicate2, removeJobDuplicate, removeTeamDuplicate,
  removeSitesDuplicate, getListofEmployees, removeRegionDuplicate, getShuffledArr
} from '../Vehicles/searchFilters'
import MovementBoxView from '../BoxView/movementBoxView'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';

const { Text, } = Typography;


const formatNumber = (value) => new Intl.NumberFormat().format(value);

const NumericInput = (props) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  }; // '.' at the end or only '-' in the input box.

  const handleBlur = () => {
    let valueTemp = value;

    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }

    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };

  const title = value ? (
    <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
  ) : (
    'Input a number'
  );
  return (
    <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Input a number"
        maxLength={25}
      />
    </Tooltip>
  );
};
const { Option } = Select;

function Movements(props) {

  const dispatch = useDispatch()
  const amount = useSelector(state => state.amount)
  const userID = useSelector(state => state.userID)
  const screenStates = useSelector(state => state.screenStates)
  const dataHistory = useSelector(state => state.dataHistory)
  const toast = useToast()

  const [activeMarker, setActiveMarker] = useState(null);
  const [directions, setDirection] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [filteredList, setFilteredList] = useState([])
  const [sitesList, setSitesList] = useState([])
  const [dummySitesList, setDummySitesList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [empList, setEmpList] = useState([])

  const [isSiteCheck, isSiteCheckSet] = useState(true)

  const [show, setShow] = useState(false);
  const [SnackbarMsg, setSnackbarMsg] = useState(false);
  const [SnackbarShow, setSnackbarShow] = useState(false);



  const [teamSelectionList, setTeamSelectionList] = useState([])
  const [siteSelectionList, setSiteSelectionList] = useState([])
  const [empSelectionList, setEmpSelectionList] = useState([])
  const [dummyFilteredList, setDummyFilteredList] = useState([])
  const [regionSelectionList, setRegionSelectionList] = useState([])

  const location = useLocation()
  const [resource_history_id, setResourceHistoryId] = useState("" + props.id); //useState(""+location.id)
  const [totalRecords, setTotalRecords] = useState("50000");
  const [startMap, setStartMapRecords] = useState(0);
  const [endMap, setEndMapRecords] = useState(100);

  const [startSite, setStartSiteRecords] = useState(0);
  const [endSite, setEndSiteRecords] = useState(100);


  const [deviceID, setDeviceID] = useState(null);
  const [empName, setEmpName] = useState(null);
  const [accommodation, setAccommodation] = useState(null);
  const [isLoading, setLoading] = React.useState(false)
  const [isMapLoading, setMapLoading] = React.useState(false)
  const [isSiteLoading, setSiteLoading] = React.useState(false)

  const [date, setDate] = useState("" + moment().format("YYYY-MM-DD"))
  const dateFormat = 'YYYY-MM-DD';
  const [places, setPlaces] = useState(props.place);
  const [datetime, setDateTime] = React.useState('')

  const [dataProp, setDataProp] = React.useState([])

  const [startTime, setStartTime] = useState('04:00');
  const [endTime, setEndTime] = useState('08:00');

  const [searchQuery, setSearchQuery] = useState("");
  const [searchBy, setSearchBy] = useState("employee");
  const [toastMsg, setToastMsg] = useState("Wrong Input");
  const [toastMsgShow, setToastMsgShow] = useState(false);
  const [resourceType, setResourceType] = React.useState([{ 'type': 'Equipment' }, { 'type': 'Human Resource' }, { 'type': 'Both' }]
  )    //Equipment   //Human Resource
  const [resourceTypeSelectionList, setResourceTypeSelectionList] = useState([])
  const [resourceType_, setResourceTypeSelect] = useState('Both')


  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString)
    console.log("OK____" + dateString);

    getListofLocations(dateString,"","","","","","")

  };

  const onStartTimeChange = (date, dateStartTime) => {
    console.log(date, dateStartTime);
    setStartTime(dateStartTime)
   // getListofLocations("",dateStartTime,"","","","","")
  };

  const onEndTimeChange = (date, dateEndTime) => {
    console.log(date, dateEndTime);
    setEndTime(dateEndTime)
  //  getListofLocations("","",dateEndTime,"","","","")

  };

  useEffect(() => {
    getListofLocations();
    getListofEmployees1()
    getListofSites()
    getListofTeams()

  }, []);


  const getListofLocations = (dateTimeString,startTimeString, endTimeString, empSelectionListString, teamSelectionListString, siteSelectionListString , resourceTypeString) => {


    var dateTime="",startTime_="", endTime_="" , empSelectionList_ = "", teamSelectionList_ = "", siteSelectionList_ = "", resourceType__=""
    
    if (dateTimeString == null || dateTimeString.length === 0) {
      dateTime = date
    } else {
      dateTime = dateTimeString
    }

    if (startTimeString == null || startTimeString.length === 0) {
      startTime_ = startTime
    } else {
      startTime_ = startTimeString
    }

    if (endTimeString == null || endTimeString.length === 0) {
      endTime_ = endTime
    } else {
      endTime_ = endTimeString
    }




    setMapLoading(true)
    isSiteCheckSet(false)
    setToastMsgShow(true)
    setToastMsg("Loading...")
    msgTimeOut()
    fetch(tssTheme.URL.retrieve_all_tracking_movement_information + "?" +
      "limit=" + totalRecords +
      "&recorded_date=" + dateTime +
      "&start_time=" + startTime_ + ":00" +
      "&end_time=" + endTime_ + ":00" +
      "&selected_resource=" + JSON.stringify(empSelectionList) +
      "&selected_teams=" + JSON.stringify(teamSelectionList) +
      "&selected_sites=" + JSON.stringify(siteSelectionList) +
      "&tracking_type=" + resourceType_
      , {
        method: 'POST',
        mode: 'cors',
        headers: {
        },
      })
      .then(function (response) {
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json();
      }).then((responseJson) => {
        console.log(responseJson.result.length);

        setFilteredList(responseJson.result.reverse())
        setDummyFilteredList(responseJson.result.reverse())
        setRoutes(responseJson.result.reverse())

        setTimeout(
          () => (setLoading(true)
            // setFilteredList(filteredList.concat( sitesList ) )
          ),
          2000
        );
        setMapLoading(false)

        setSnackbarMsg("Success.")
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      })
      .catch((err) => {
        console.log("err ", err)
        setMapLoading(false)
        setTimeout(
          () => setLoading(true),
          2000
        );

        setSnackbarMsg("Message:" + err)
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      });
  }

  const getListofSites = () => {
    setSiteLoading(false)
    fetch(tssTheme.URL.siteList, {
      method: 'POST',
      mode: 'cors',
      headers: {
      },
    })
      .then(function (response) {
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json();
      }).then((responseJson) => {
        setSiteLoading(true)
        setSitesList((responseJson.result))
        setDummySitesList(responseJson.result)

        setSnackbarMsg("Success.")
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      })
      .catch((err) => {
        console.log("err ", err)
        setSiteLoading(true)

        setSnackbarMsg("Message:" + err)
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      });
  }

  const getListofTeams = () => {
    setSiteLoading(false)
    fetch(tssTheme.URL.allTeams, {
      method: 'POST',
      mode: 'cors',
      headers: {
      },
    })
      .then(function (response) {
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json();
      }).then((responseJson) => {
        setSiteLoading(true)
        setTeamList((responseJson.result))


        setSnackbarMsg("Success.")
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      })
      .catch((err) => {
        console.log("err ", err)
        setSiteLoading(true)

        setSnackbarMsg("Message:" + err)
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      });
  }


  const getListofEmployees1 = () => {
    fetch(tssTheme.URL.allEmployees, {
      method: 'POST',
      mode: 'cors',
      headers: {
      },
    })
      .then(function (response) {
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json();
      }).then((responseJson) => {
        setEmpList((responseJson.result))

        setSnackbarMsg("Success.")
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );

      })
      .catch((err) => {
        console.log("err ", err)
        setSnackbarMsg("Message:" + err)
        setSnackbarShow(true)
        setTimeout(
          () => setSnackbarShow(false),
          3000
        );
      });
  }



  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const searchList = routes.filter((item) => {
      return searchBy === "employee" ? ("" + item.resource_name).toLowerCase().indexOf(query.toLowerCase()) !== -1
        : null
    });
    setFilteredList(searchList);

  }

  const handleEndTotalChange = (event) => {
    const query = event.target.value;
    if (!isNumeric(query)) {
      setToastMsgShow(true)
      setToastMsg("Wrong Input...")
      msgTimeOut()
      return;
    }
    if (query > routes.length) {
      setEndMapRecords(routes.length - 1)
      setToastMsgShow(true)
      setToastMsg("Wrong Input...")
      msgTimeOut()
    }
    setEndMapRecords(query)
    var c = [...dummyFilteredList.slice(startMap, query), ...dummySitesList.slice(startSite, endSite)]
    setFilteredList(c)
  }

  const handleStartTotalChange = (event) => {
    const query = event.target.value;
    if (!isNumeric(query)) {
      setToastMsgShow(true)
      setToastMsg("Wrong Input...")
      msgTimeOut()
      return;
    }
    setStartMapRecords(query)
    var c = [...dummyFilteredList.slice(query, endMap), ...getShuffledArr(dummySitesList).slice(startSite, endSite)]
    setFilteredList(c)
  }
  const handleStartSiteChange = (event) => {
    const query = event.target.value;
    if (!isNumeric(query)) {
      setToastMsgShow(true)
      setToastMsg("Wrong Input...")
      msgTimeOut()
      return;
    }
    console.log('isSiteCheck:' + isSiteCheck)
    setStartSiteRecords(query)
    if (isSiteCheck) {
      var c = [...dummyFilteredList.slice(startMap, endMap), ...getShuffledArr(dummySitesList).slice(query, endSite)]
      setFilteredList(c)
    }
  }

  const handleEndSiteChange = (event) => {
    const query = event.target.value;
    if (!isNumeric(query)) {
      setToastMsgShow(true)
      setToastMsg("Wrong Input...")
      msgTimeOut()
      return;
    }
    setEndSiteRecords(query)
    console.log('isSiteCheck:' + isSiteCheck)

    if (isSiteCheck) {
      // setDummyFilteredList(filteredList)  
      var c = [...dummyFilteredList.slice(startMap, endMap), ...dummySitesList.slice(startSite, query)]
      setFilteredList(c)
    }
  }


  const msgTimeOut = () => {
    setTimeout(
      function () {
        setToastMsgShow(false)

      }, 3000);
  }

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  const onSiteSelect = (event) => {
    setSiteSelectionList(event)
    console.log(event)
  }

  const onEmpSelect = (event) => {
    setEmpSelectionList(event)
    console.log(event)
  }
  const onRegionSelect = (event) => {
    setRegionSelectionList(event)
    console.log("Length:" + event.length)

    var filteredArray = [];
    event.map((item1) => {
      filteredArray = [...filteredArray, ...sitesList.filter(item => ("" + item.region) === ("" + item1.region))]
    })
    setDummySitesList(filteredArray)

    if (event.length === 0 && isSiteCheck) {
      var c = [...dummyFilteredList.slice(startMap, endMap), ...sitesList.slice(startSite, endSite)]
      setFilteredList(c)
      setDummySitesList(sitesList)

    } else {

      if (isSiteCheck) {
        var c = [...dummyFilteredList.slice(startMap, endMap), ...getShuffledArr(filteredArray).slice(startSite, endSite)]
        setFilteredList(c)
      }

    }

  }


  const onTeamSelect = (event) => {
    setTeamSelectionList(event)
    console.log(event)
    getListofLocations()
  }


  const onTypeSelect = (event) => {
    setResourceTypeSelectionList(event)
    console.log(event[0].type)
    setResourceTypeSelect(event[0].type)

    getListofLocations("","","","","","",event[0].type)

  }

  const showMessage = () => {
    return <Snackbar
      open={toastMsgShow}
      autoHideDuration={300}
      message={"" + toastMsg}
    />
  }


  const SnackbarView = () => {
    return (
      <Snackbar open={SnackbarShow} autoHideDuration={3000} >
        <div style={{ backgroundColor: tssTheme.COLORS.BLACK, color: tssTheme.COLORS.WHITE, padding: 20, borderRadius: 5 }}>
          <Text style={{ color: Theme.COLORS.WHITE }}></Text>
          <Text style={{ color: Theme.COLORS.RED }}> {"" + SnackbarMsg}! </Text >
        </div>
      </Snackbar>
    )
  }

  const SnackbarSuccessView = () => {
    return (
      <Snackbar open={SnackbarShow} autoHideDuration={3000} >
        <div style={{ backgroundColor: tssTheme.COLORS.BLACK, color: tssTheme.COLORS.WHITE, padding: 20, borderRadius: 5 }}>
          <Text style={{ color: Theme.COLORS.WHITE }}> Failed to Load Data. </Text>
          <Text style={{ color: Theme.COLORS.RED }}> {"" + SnackbarMsg}! </Text >
        </div>
      </Snackbar>
    )
  }

  const SelectType = () => {
    return (

      <Dropdown style={{ marginLeft: 0, width: 170 }}>
        <Dropdown.Toggle
          variant="secondary"
          style={{ backgroundColor: tssTheme.COLORS.LightWhite, color: tssTheme.COLORS.lightGray, borderColor: '#D3D3D3', borderRadius: 2 }}
        >
          {resourceType}
        </Dropdown.Toggle>
        <Dropdown.Menu variant="" style={{}}  >
          <Dropdown.Item style={{}}
            onClick={() => setResourceType("Equipment")}>
            Equipment
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setResourceType("Human Resource")} >Human Resource</Dropdown.Item>
          <Dropdown.Item onClick={() => setResourceType("Both")} >Both</Dropdown.Item>
        </Dropdown.Menu>

      </Dropdown>
    );
  }




  const selectAfter = (
    <Select defaultValue="employee"
      onChange={(event) => handleSearchBy(event)}
      className="select-after">
      <Option value="employee">employee</Option>
      {/* <Option value="department">department</Option>
              <Option value="team">team</Option>
              <Option value="site">site</Option> */}
    </Select>
  );
  const handleSearchBy = (event) => {
    setSearchBy(event);
  };

  // isSiteCheck?1000: 

  let markers =
    filteredList.slice(startMap, isSiteCheck ? endMap + endSite : endMap) !== undefined &&
    filteredList.slice(startMap, isSiteCheck ? endMap + endSite : endMap) &&
    filteredList.slice(startMap, isSiteCheck ? endMap + endSite : endMap).map((item, index) => ({
      id: index + 1,
      name: item.recorded_date,
      item: item,
      position: {
        lat: Number(item && item.latitude, 10),
        lng: Number(item && item.longitude, 10)
      }
    }));

  // console.log("@@@markser", routes);

  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };


  const handleActiveKey = (event) => {
    console.log(event)

  };


  const TabViewBar = () => {
    return (
      <Tabs
        defaultActiveKey="Filters"
        transition={true}
        id="noanim-tab-example"
        className="mb-3 text-primary"
        onSelect={handleActiveKey}
        style={{}}
      >
        <Tab eventKey="Filters"
          title={<span style={{ alignSelf: 'center' }}>
            <FilterAlt sx={{ fontSize: 20, marginRight: 1 }} />Filters </span>
          }
          style={{ backgroundColor: tssTheme.COLORS.WHITE }}

        >
          {HumanResourceFilters1()}
        </Tab>


        {/* <Tab eventKey="Settings"
          title={<span style={{ alignSelf: 'center' }}>
            <Workspaces sx={{ fontSize: 20, marginRight: 1 }} />Settings</span>
          }
          style={{ alignContent: 'center' }}
        >
           {HumanResourceFilters()} 
        </Tab> */}

        {/* <Tab eventKey="map" title="Map" >
  </Tab>  */}
      </Tabs>
    )
  }

  const HumanResourceFilters1 = () => {
    return (
      <Center flex='1' style={{
        backgroundColor: tssTheme.COLORS.WHITE,
        borderWidth:1,
        borderColor:tssTheme.COLORS.PRIMARY,
        padding: 20, borderRadius: 1
      }}>
        <Flex gap={10} direction="horizontal" className="mx-auto">
          <div className="me-auto" style={{ marginRight: 10, height: 50,width: 120 }}>
            <input type="date"
              value={date}
              name="appt"
              onChange={(item) => {
                onDateChange("", item.target.value + "")
              }}
              style={{width: 120,
                height: 50, padding: 10, color: tssTheme.COLORS.borderColor,
                borderColor: tssTheme.COLORS.borderColor, borderWidth: 1, borderRadius:4
              }}
            />
          </div>


          <div className="me-auto" style={{ marginRight: 2, height: 50, }}>
            <input type="time" value={startTime}
              name="appt"
              onChange={(item) => {
                onStartTimeChange("", item.target.value + "")
              }}
              style={{
                height: 50, width: '100%', padding: 10, color: tssTheme.COLORS.borderColor,
                borderColor: tssTheme.COLORS.borderColor, borderWidth: 1, borderRadius:4
              }}
            />
          </div>



          <div className="me-auto" style={{ marginRight: 0, height: 50, }}>
            <input type="time" value={endTime}
              name="appt"
              onChange={(item) => {
                onEndTimeChange("", item.target.value + "")
              }}
              style={{
                height: 50, width: '100%', padding: 10, color: tssTheme.COLORS.borderColor,
                borderColor: tssTheme.COLORS.borderColor, borderWidth: 1, borderRadius:4
              }}
            />
          </div>

          <div className="me-auto" style={{ marginRight: 10, height: 50,
             width: 150, backgroundColor: '#FFF' }}>
            <Multiselect
              options={resourceType} // Options to display in the dropdown
              selectedValues={resourceTypeSelectionList} // Preselected value to persist in dropdown
              onSelect={onTypeSelect} // Function will trigger on select event
              onRemove={onTypeSelect} // Function will trigger on remove event
              displayValue="type" // Property name to display in the dropdown options
              placeholder={"Select Type"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
              singleSelect={true}
              style={{ width: '100%',height: 50, fontSize: 15, borderWidth: 0 }}
            />
          </div>

          <div className="me-auto" style={{ marginRight: 10, 
            width: 150, backgroundColor: tssTheme.COLORS.WHITE }}>
            <Multiselect
              options={empList} // Options to display in the dropdown
              selectedValues={empSelectionList} // Preselected value to persist in dropdown
              onSelect={onEmpSelect} // Function will trigger on select event
              onRemove={onEmpSelect} // Function will trigger on remove event
              selectedValueDecorator={(v) => v.charAt(0)}
              displayValue="resource_name" // Property name to display in the dropdown options
              placeholder={"Search Emp."}
              showCheckbox={true}
              showArrow={true}
            
              style={{ width: '100%',color:'#000' }}
            />
          </div>


          <div className="me-auto" style={{ marginRight: 10, 
            width: 150, backgroundColor: tssTheme.COLORS.WHITE }}>

            {isSiteLoading ? <Multiselect
              options={sitesList} // Options to display in the dropdown
              selectedValues={siteSelectionList} // Preselected value to persist in dropdown
              onSelect={onSiteSelect} // Function will trigger on select event
              onRemove={onSiteSelect} // Function will trigger on remove event
              displayValue="assigned_sites" // Property name to display in the dropdown options
              placeholder={"Select Site"}
              showCheckbox={true}
              showArrow={true}
              style={{ width: '100%', }}
            /> : <Spin />}
          </div>


          <div className="me-auto" style={{ marginRight: 10, height: 50, 
            width: 150, backgroundColor: tssTheme.COLORS.WHITE }}>
            <Multiselect
              options={teamList} // Options to display in the dropdown
              selectedValues={teamSelectionList} // Preselected value to persist in dropdown
              onSelect={onTeamSelect} // Function will trigger on select event
              onRemove={onTeamSelect} // Function will trigger on remove event
              displayValue="team" // Property name to display in the dropdown options
              placeholder={"Select Team"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
              selectedValueDecorator={(v) => v.charAt(0)}
              style={{ width: '100%', backgroundColor: '#FFF' }}
            />

          </div>


        <div className="me-auto" style={{ marginRight: 10, height: 50, 
            width: 100 }}>

            <Button
              style={{
                height: 50, width: '100%',
                fontSize: 15, borderRadius: 0, color: tssTheme.COLORS.PRIMARY
              }}
              onClick={() => { getListofLocations() }}>
              Search
            </Button>
          </div> 
          <Box>
            {isMapLoading ? <Spin /> : null}
          </Box>
        </Flex>
      </Center>
    )
  }



  const HumanResourceFilters = () => {
    return (
      <SimpleGrid minChildWidth='140px' spacing={'10px'}>
        <Box >
          <Box style={{ margin: 5, }}>
            <input type="date"
              value={date}
              name="appt"
              onChange={(item) => {
                onDateChange("", item.target.value + "")
              }}
              style={{ height: 40, padding: 10, color: tssTheme.COLORS.lightGray, marginRight: 5, borderColor: '#fafafa' }}
            />
          </Box>
        </Box>
        <Box >
          <Box style={{ margin: 5, width: '100%', }}>
            <input type="time" value={startTime}
              name="appt"
              onChange={(item) => {
                onStartTimeChange("", item.target.value + "")
              }}
              style={{ height: 40, width: '100%', padding: 10, color: tssTheme.COLORS.lightGray, marginRight: 5, borderColor: '#fafafa' }}
            />
          </Box>

        </Box>
        <Box >
          <Box style={{ margin: 5, width: '100%', }}>
            <input type="time" value={endTime}
              name="appt"
              onChange={(item) => {
                onEndTimeChange("", item.target.value + "")
              }}
              style={{ height: 40, width: '100%', padding: 10, color: tssTheme.COLORS.lightGray, marginRight: 5, borderColor: '#fafafa' }}
            />
          </Box>
        </Box>

        <Box >
          <Box style={{ margin: 5, width: '100%', }}>
            <Multiselect
              options={resourceType} // Options to display in the dropdown
              selectedValues={resourceTypeSelectionList} // Preselected value to persist in dropdown
              onSelect={onTypeSelect} // Function will trigger on select event
              onRemove={onTypeSelect} // Function will trigger on remove event
              displayValue="type" // Property name to display in the dropdown options
              placeholder={"Select Type"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
              singleSelect={true}
              style={{ width: '100%', fontSize: 15, }}
            />
          </Box>
        </Box>

        <Box  >
          <Box style={{ margin: 5, width: '100%', }}>
            <Multiselect
              options={empList} // Options to display in the dropdown
              selectedValues={empSelectionList} // Preselected value to persist in dropdown
              onSelect={onEmpSelect} // Function will trigger on select event
              onRemove={onEmpSelect} // Function will trigger on remove event
              selectedValueDecorator={(v) => v.charAt(0)}
              displayValue="resource_name" // Property name to display in the dropdown options
              placeholder={"Search Emp."}
              showCheckbox={true}
              showArrow={true}
              style={{ width: '100%', }}
            />
          </Box>

        </Box>
        <Box >
          <Box style={{ margin: 5, width: '100%', }}>

            {isSiteLoading ? <Multiselect
              options={sitesList} // Options to display in the dropdown
              selectedValues={siteSelectionList} // Preselected value to persist in dropdown
              onSelect={onSiteSelect} // Function will trigger on select event
              onRemove={onSiteSelect} // Function will trigger on remove event
              displayValue="assigned_sites" // Property name to display in the dropdown options
              placeholder={"Select Site"}
              showCheckbox={true}
              showArrow={true}
              style={{ width: '100%', }}
            /> : <Spin />}
          </Box>

        </Box>
        <Box  >
          <Box style={{ margin: 5, width: '100%', }}>
            <Multiselect
              options={teamList} // Options to display in the dropdown
              selectedValues={teamSelectionList} // Preselected value to persist in dropdown
              onSelect={onTeamSelect} // Function will trigger on select event
              onRemove={onTeamSelect} // Function will trigger on remove event
              displayValue="team" // Property name to display in the dropdown options
              placeholder={"Select Team"}
              showCheckbox={true}
              avoidHighlightFirstOption={false}
              showArrow={true}
              selectedValueDecorator={(v) => v.charAt(0)}
              style={{ width: '100%', }}
            />

          </Box>
        </Box>

        <Box>
          <Button
            style={{ height: 40, width: '100%', fontSize: 15, margin: 5, borderRadius: 2, color: tssTheme.COLORS.PRIMARY }}
            onClick={() => { getListofLocations() }}>
            Search
          </Button>
        </Box>
        <Box>
          {isMapLoading ? <Spin /> : null}
        </Box>

      </SimpleGrid>
    )
  }

  const paginationFilters1 = () => {
    return (
      <Flex style={{ marginTop: 10 }}>
        <Box p='4' bg='red.400'>

          <FormControlLabel control={<Checkbox
            checked={isSiteCheck}
            color="success"
            onChange={(item) => {
              console.log(item.target.checked)
              isSiteCheckSet(item.target.checked)
              if (item.target.checked) {
                // setDummyFilteredList(filteredList)  
                var c = [...dummyFilteredList.slice(startMap, endMap), ...dummySitesList.slice(startSite, endSite)]
                setFilteredList(c)
              }
              else {
                let filteredArray = dummyFilteredList.filter(item => item.tracking_type !== "site")
                setFilteredList(filteredArray)
              }

            }} />}
            label="View Sites" />

        </Box>

        {isSiteCheck ?
          <Flex>
            <Center>
              <Multiselect
                options={removeRegionDuplicate(sitesList)} // Options to display in the dropdown
                selectedValues={regionSelectionList} // Preselected value to persist in dropdown
                onSelect={onRegionSelect} // Function will trigger on select event
                onRemove={onRegionSelect} // Function will trigger on remove event
                // selectedValueDecorator={(v)=>v.charAt(0)}
                displayValue="region" // Property name to display in the dropdown options
                placeholder={"Search region."}
                showCheckbox={true}
                showArrow={true}
                singleSelect={false}
                style={{ width: '100%', }}
              />
            </Center>
            <Center>
              <Box style={{ borderWidth: 12, borderColor: tssTheme.COLORS.BLACK, }}>
                {siteDataPagination()}
              </Box>
            </Center>
          </Flex>
          : null}

        <Spacer />
        <Box p='4' bg='green.400'>
          {mapDataPagination()}
        </Box>
      </Flex>
    )

  }

  const siteDataPagination = () => {
    return (
      <Box style={{ borderWidth: 12, borderColor: tssTheme.COLORS.BLACK, }}>
        <form style={{ borderWidth: 12, borderColor: tssTheme.COLORS.BLACK, marginRight: 10 }}>
          <label>
            <input
              type="text" name="startSite"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={handleStartSiteChange}
              value={startSite}
            />
            <Text>-</Text>
            <input type="text" name="endSite"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={handleEndSiteChange}
              value={endSite} />
            <Text>/</Text>
            <input type="text" name="length"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={{}}
              editable={false}
              value={dummySitesList.length} />
          </label>
        </form>
      </Box>
    )
  }

  const mapDataPagination = () => {
    return (
      <Box style={{ borderWidth: 12, borderColor: tssTheme.COLORS.BLACK, }}>

        <form style={{ borderWidth: 12, borderColor: tssTheme.COLORS.BLACK, marginRight: 10 }}>
          <label>
            <input type="text" name="name"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={handleStartTotalChange}
              value={startMap}
            />
            <Text>-</Text>
            <input type="text" name="name"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={handleEndTotalChange}
              value={endMap} />
            <Text>/</Text>
            <input type="text" name="name"
              style={{ margin: 3, width: 40, color: tssTheme.COLORS.lightGray, borderWidth: 0, textAlign: 'center', backgroundColor: '#fafafa' }}
              onChange={{}}
              editable={false}
              value={routes.length} />
          </label>
        </form>
      </Box>
    )

  }


  const paginationFilters = () => {
    return (
      <Flex>
        <SimpleGrid minChildWidth='140px' spacing={'10px'}>
          <Box >
            <Box style={{ margin: 5, }}>

            </Box>
          </Box>



        </SimpleGrid>
        <Spacer />
        <Box p='4' bg='green.400'>
          {mapDataPagination()}
        </Box>
      </Flex>
    )
  }


  const handleOnLoad = map => {
    const bounds = new window.google.maps.LatLngBounds();
    markers && markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);

  };

  return (
    isLoading ?
      <div style={{ height: "100%", width: "100%", }}>

        {/* <ArrowBack
          onClick={()=>{dispatch(actionCreators.screenStates("dashboard"))}}
          sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }}/> */}
        {TabViewBar()}
        {SnackbarView()}
        {paginationFilters1()}

        {/* {paginationFilters()} */}
        <GoogleMap
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: "100%", height: "78vh", marginTop: 10 }}
        >

          {

            markers &&
            markers.map(({ id, name, item, position }, i, arr) => {
              const icon1 = {
                url:
                  item.tracking_type === "Equipment" ? Theme.URL.carIcon :
                    item.tracking_type === "Human Resource" ?
                      "" + require('../../assets/images/man-standing-up.png')
                      : require('../../assets/images/pisaTower.png'),

                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };
              const startIcon = {

                url: "" + tssTheme.URL.startIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };

              const endIcon = {
                url:
                  "" + tssTheme.URL.endIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };

              const siteIcon = {
                url:
                  "" + tssTheme.URL.siteIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };


              // if(item.tracking_type === 'site')
              // return(<Marker
              //   key="site"
              //   position={position}
              //   onClick={() => handleActiveMarker("site")}
              //   // label={{ text: `START`, color: "black" }}
              //   icon={siteIcon}
              // >
              //   {
              //   activeMarker === "site" ? (
              //     <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              //       <div>
              //       {/* {mapEquipmentDetailView()} */}
              //       <Text strong>Site: {item.sequence}</Text>  
              //       <br/>
              //       <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Recorded Date: {item.recorded_date}</Text> 
              //     <br/>
              //     <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Latitude: {item.latitude}</Text> 
              //     <br/> 
              //     <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Longitude: {item.longitude}</Text> 
              //     <br/> 
              //     <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Type: {item.tracking_type}</Text> 
              //     <br/>
              //      <br/>
              //      </div>
              //     </InfoWindow>
              //   ) : null
              //   }
              // </Marker>
              //   )

              // if(item.tracking_type === 'Human Resource')
              // return (
              //   <Marker
              //   key={id}
              //     position={position}
              //     onClick={() => handleActiveMarker(id)}
              //     // label={{ text: `START`, color: "black" }}
              //     icon={startIcon}
              //   >
              //   {activeMarker === id ? (
              //       <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              //         <div>
              //         {/* MovementBoxView un comment */}
              //         <MovementBoxView 
              //           dateTime={item.recorded_date}
              //           data={item} 
              //           /> 

              //        </div>
              //       </InfoWindow>
              //     ) : null}
              //   </Marker>
              // );

              return (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                  // label={{ text:i===arr.length-1?null: `${(id - 1)}`, color: "white" }}
                  icon={icon1}            >
                  {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>

                        <MovementBoxView
                          dateTime={item.recorded_date}
                          data={item}
                        />

                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              );
            })}
        </GoogleMap>
        <Snackbar
          open={toastMsgShow}
          autoHideDuration={300}
          message={"" + toastMsg}
        />
      </div>
      : <div style={{ paddingLeft: '45%', paddingTop: '19%' }}>
        <OrbitSpinner size={100} style={{}} color={tssTheme.COLORS.BLACK} />
        <br /> <Text strong>Loading...</Text>
      </div>
  );
}

export default Movements;