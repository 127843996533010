/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Component }  from 'react';

import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Spin
  } from "antd";
  
  import { ToTopOutlined } from "@ant-design/icons";
  import { Link } from "react-router-dom";

  // Images
  import ava1 from "../assets/images/logo-shopify.svg";
  import ava2 from "../assets/images/logo-atlassian.svg";
  import ava3 from "../assets/images/logo-slack.svg";
  import ava5 from "../assets/images/logo-jira.svg";
  import ava6 from "../assets/images/logo-invision.svg";
  import face from "../assets/images/face-1.jpg";
  import face2 from "../assets/images/face-2.jpg";
  import face3 from "../assets/images/face-3.jpg";
  import face4 from "../assets/images/face-4.jpg";
  import face5 from "../assets/images/face-5.jpeg";
  import face6 from "../assets/images/face-6.jpeg";
  import pencil from "../assets/images/pencil.svg";
  import axios from "axios";
  import GoogleMapReact from 'google-map-react';

  const { Title } = Typography;
  
  const formProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  // table code start
  const columns = [
    {
      title: "Trip ID",
      dataIndex: "trip_id",
      key: "trip_id",
      width: "1%",
    },
      {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "32%",
    },
    {
      title: "Latitude",
      dataIndex: "site_latitude",
      key: "site_latitude",
    },
    {
      title: "Longitude",
      key: "site_longitude",
      dataIndex: "site_longitude",
    },
    {
      title: "Date",
      key: "site_name",
      dataIndex: "site_name",
    },
  ];
  
  const data = [
    {
      key: "1",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face2}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Michael John</Title>
              <p>michael@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Manager</Title>
            <p>Organization</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button type="primary" className="tag-primary">
            ONLINE
          </Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>23/04/18</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
  
    {
      key: "2",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face3}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Alexa Liras</Title>
              <p>alexa@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Programator</Title>
            <p>Developer</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button className="tag-badge">ONLINE</Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>23/12/20</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
  
    {
      key: "3",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Laure Perrier</Title>
              <p>laure@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Executive</Title>
            <p>Projects</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button type="primary" className="tag-primary">
            ONLINE
          </Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>03/04/21</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
    {
      key: "4",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face4}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Miriam Eric</Title>
              <p>miriam@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Marketing</Title>
            <p>Organization</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button type="primary" className="tag-primary">
            ONLINE
          </Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>03/04/21</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
    {
      key: "5",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face5}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Richard Gran</Title>
              <p>richard@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Manager</Title>
            <p>Organization</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button className="tag-badge">ONLINE</Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>23/03/20</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
  
    {
      key: "6",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={face6}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>John Levi</Title>
              <p>john@mail.com</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>Tester</Title>
            <p>Developer</p>
          </div>
        </>
      ),
  
      status: (
        <>
          <Button className="tag-badge">ONLINE</Button>
        </>
      ),
      employed: (
        <>
          <div className="ant-employed">
            <span>14/04/17</span>
            <a href="#pablo">Edit</a>
          </div>
        </>
      ),
    },
  ];
  // project table start
  const project = [
    {
      title: "COMPANIES",
      dataIndex: "name",
      width: "32%",
    },
    {
      title: "BUDGET",
      dataIndex: "age",
    },
    {
      title: "STATUS",
      dataIndex: "address",
    },
    {
      title: "COMPLETION",
      dataIndex: "completion",
    },
  ];
  const dataproject = [
    {
      key: "1",
  
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}>Spotify Version</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">$14,000</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">working</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress percent={30} size="small" />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  
    {
      key: "2",
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava2} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}>Progress Track</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">$3,000</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">working</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress percent={10} size="small" />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  
    {
      key: "3",
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava3} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}> Jira Platform Errors</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">Not Set</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">done</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress percent={100} size="small" format={() => "done"} />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  
    {
      key: "4",
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava5} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}> Launch new Mobile App</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">$20,600</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">canceled</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress
              percent={50}
              size="small"
              status="exception"
              format={() => "50%"}
            />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  
    {
      key: "5",
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava5} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}>Web Dev</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">$4,000</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">working</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress percent={80} size="small" />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  
    {
      key: "6",
      name: (
        <>
          <Avatar.Group>
            <Avatar className="shape-avatar" src={ava6} size={25} alt="" />
            <div className="avatar-info">
              <Title level={5}>Redesign Online Store</Title>
            </div>
          </Avatar.Group>
        </>
      ),
      age: (
        <>
          <div className="semibold">$2,000</div>
        </>
      ),
      address: (
        <>
          <div className="text-sm">canceled</div>
        </>
      ),
      completion: (
        <>
          <div className="ant-progress-project">
            <Progress percent={0} size="small" />
            <span>
              <Link to="/">
                <img src={pencil} alt="" />
              </Link>
            </span>
          </div>
        </>
      ),
    },
  ];
  
  const AnyReactComponent = ({ text }) => <div>{text}</div>;


  class tssHome extends React.Component {

    static defaultProps = {
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 11
    };


    constructor() {
        super(); 
        this.state = {
          isLoading: true,
          //Loading state used while loading the data for the first time
          dataSource: [],
          //Data Source for the FlatList
          fetching_from_server: false,
          //Loading state used while loading more data
          text: '',
          results: [],
          response:'',
          url:'http://157.245.98.161:8069/tss_attendance/get_assigned_sites?resource_id=346&resource_datetime=2022-04-28',
          teamMembers:'http://157.245.98.161:8069/tss_attendance/get_trip_members?trip_id=59000',
          testData:"https://tsstrackers.000webhostapp.com/php/index.php",
          jamSam:'https://itisall.online/jamit/itisall-Online-Connectivity/user_info/allUsers.php'
        }; 
      }

      componentDidMount() {

      // Passing configuration object to axios
    
     fetch(''+this.state.url, {
      method: 'POST',
      mode: 'cors',
      headers: {
      },
     })
     .then(function (response) {
      if (!response.ok) {
         return response.text().then(result => Promise.reject(new Error(result)));
      }
      return response.json();
  }).then((responseJson) => {
      console.log(responseJson);
      this.setState({
          dataSource: responseJson,
          isLoading: false
         });
    })
    .catch((err)=>{
        console.log("err ",err)
    });

       // this.webCall()
	}

      lapsList(item) {
          return (
            <h1>{"item"}</h1>
          )
      }

    async getUser() {
        try {
          // ⛔️ TypeError: Failed to fetch
          // 👇️ incorrect or incomplete URL
          const response = await fetch('https://example.com/does-not-exist');
      
          if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
          }
      
          const result = await response.json();
          return result;
        } catch (err) {
          console.log(err);
        }
     }

    onSubmit = () => {
        this.lapsList("responseJson")
        // event.preventDefault();
        fetch(this.state.url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
          })
        })
          .then(res => {
            this.setState({dataSource:res})
              console.log("response: ", res);
          })
          .catch(err => {
            console.log("error:", err);
          });
      };  
      
 webCall=()=>{

    return fetch('https://cors-anywhere.herokuapp.com/'+this.state.url)
           .then((response) => response.json())
           .then((responseJson) => {
            this.lapsList(responseJson)
               console.log(responseJson)
             this.setState({
               isLoading: false,
               dataSource: responseJson
             }, function() {
               // In this block you can do something with new state.
             });
           })
           .catch((error) => {
             console.error(error);
           });
   }
  
    getList(){
        fetch('http://erp.tawasolservices.com:8069/tss_attendance/check_allow_manual_interaction?resource_id=46', {
            method: 'POST',
            //Request Type
          })
            .then((response) => response.json())
            //If response is in json then in success
            .then((responseJson) => {
              //Success
              // alert(JSON.stringify(responseJson.data.fixtures[0]));
              console.log(responseJson.events);
              this.setState({url:responseJson.events})
              if(responseJson.events.length>0){
                this.setState({
                  dataSource: responseJson.events,
                isLoading: false,
       
              });
            }else{
              this.setState({
                isLoading: false,
              });
            }
            })
            //If response is not in json then in error
            .catch((error) => {
              //Error
              // alert(JSON.stringify(error));
              console.error(error);
              this.setState({
                isLoading: false,
              });
            }); 
    }
     onChange = (e) => console.log(`radio checked:${e.target.value}`);
  
    render() {
        const { navigation } = this.props;
        const { DataisLoaded, items } = this.state;
    
        return (
        <div className="tabled">
      
         <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Assigned Sites"
                extra={
                  <>
                    <Radio.Group onChange={this.onChange} defaultValue="a">
                      {/* <Radio.Button value="a">All</Radio.Button>
                      <Radio.Button value="b">ONLINE</Radio.Button> */}
                    </Radio.Group>
                  </>
                }
              >
               
            {
                this.state.isLoading?
                 <div className="table-responsive"
                 style={{
                   marginTop:20
                  }}>
          <Spin
          style={{
            width:'100%',
            height:100,
            alignContent:'center',justifyContent:'center',alignSelf:'center'}}
          />
                 </div> 
                :
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={this.state.dataSource.data}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>  
             }
    
              </Card>
            </Col>
          </Row>
        </div>
    )}
  }
  
  export default tssHome;
  