import React, { useState, useEffect, useRef } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { tssTheme } from '../../constants'
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  OrbitSpinner,
} from 'react-epic-spinners'
import {
  Row,
  Col,
  Typography,
  Spin,
  Input, TimePicker,
  DatePicker, Tooltip
} from "antd";

import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../redux/state';

import { Flex, Spacer, Center, Square, Box } from '@chakra-ui/react'
// import HeaderIcons from '../../components/topbar'

import {
  ArrowBack,
  Refresh
} from '@mui/icons-material';
import BoxView from '../BoxView'

const { Text, } = Typography;

function MapScreen(props) {
  const dispatch = useDispatch()
  const [activeMarker, setActiveMarker] = useState(null);
  const [directions, setDirection] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [resource_history_id, setResourceHistoryId] = useState("" + props.id); //useState(""+location.id)
  const [totalRecords, setTotalRecords] = useState("300");
  const [deviceID, setDeviceID] = useState(null);
  const [empName, setEmpName] = useState(null);
  const [accommodation, setAccommodation] = useState(null);
  const [isLoading, setLoading] = React.useState(false)
  const [isMapLoading, setMapLoading] = React.useState(false)
  const [date, setDate] = useState(props.datetime)
  // const [date, setDate] = useState(""+moment().format("YYYY-MM-DD"))

  const dateFormat = 'YYYY-MM-DD';
  const [total, setValue] = useState('300');
  const [userData, setPlaces] = useState(props.place);
  const [datetime, setDateTime] = React.useState('')

  const [dataProp, setDataProp] = React.useState([])
  const [height, setHeight] = useState(0);

  const [startTime, setStartTime] = useState('06:00:00');
  const [endTime, setEndTime] = useState('');

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString)
    getListofLocations(props.id, userData.tracking_type === "Human Resource" ? userData.driver_id : userData.device_id, dateString);
  };

  const onStartTimeChange = (date, startTimeString) => {
    console.log(date, startTimeString);
    setStartTime(startTimeString)
    getListofLocations(props.id, userData.tracking_type === "Human Resource" ? userData.driver_id : userData.device_id, "", startTimeString, "");

  };

  const onEndTimeChange = (date, endTimeString) => {
    console.log(date, endTimeString);
    setEndTime(endTimeString)
    getListofLocations(props.id, userData.tracking_type === "Human Resource" ? userData.driver_id : userData.device_id, "", "", endTimeString);

  };

  useEffect(() => {
    console.log("Device ID:" + userData.device_id)
    console.log(" ID:" + props.id)
    setResourceHistoryId(props.id)
    setDeviceID(userData.device_id)
    setEmpName(userData.driver_name)
    setAccommodation(userData.accommodation)
    setPlaces(props.place)

    getListofLocations(props.id, userData.tracking_type === "Human Resource" ? userData.driver_id : userData.device_id);
  }, []);

  const getListofLocations = (id, device_id, dateString, startingTimeString, endingTimeString) => {
    var dateTime = "", startingTime = "", endingTime = ""
    if (dateString == null || dateString.length === 0) {
      dateTime = date
    } else {
      dateTime = dateString
    }

    if (startingTimeString == null || startingTimeString.length === 0) {
      startingTime = startTime
    } else {
      startingTime = startingTimeString
    }

    if (endingTimeString == null || endingTimeString.length === 0) {
      endingTime = endTime
    } else {
      endingTime = endingTimeString
    }

    // setResourceHistoryId(30326)
    setLoading(false)
    console.log("places.tracking_type" + device_id)
    setMapLoading(true)
    fetch(tssTheme.URL.retrieve_vehicle_tracking_movement_information +
      "?resource_history_id=" + id +
      "&driver_id=" + userData.driver_id +
      "&fleet_id=" + userData.fleet_id +
      "&device_id=" + device_id +
      "&limit=" + totalRecords +
      "&start_time=" + startingTime + "" +
      "&end_time=" + endingTime + "" +
      "&tracking_type=" + userData.tracking_type +
      "&recorded_date=" + dateTime + "", {
      method: 'POST',
      mode: 'cors',
      headers: {
      },
    })
      .then( (response) =>{
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json();
      }).then((responseJson) => {
        console.log(responseJson.result);
        setRoutes(responseJson?.result?.reverse())
        responseJson?.result?.map((value) => {
          // console.log("Last : "+value.recorded_date)
          setEndTime('' + moment("" + value.recorded_date).format('HH:mm:ss'))
        })
        //  console.log("Last : "+moment(''+responseJson.result[responseJson.result.length-1].recorded_date, 'HH:mm:ss'))
        setTimeout(
          () => setLoading(true),
          200
        );

        setMapLoading(false)
      })
      .catch((err) => {
        console.log("err ", err)
        setMapLoading(false)
        setTimeout(
          () => setLoading(true),
          2000
        );
      });
  }

  const HeaderIcons = () => {
    return (
      <Flex color='white'>
        <Center w='40px' h='40px' bg={tssTheme.COLORS.PRIMARY}
          onClick={() => {
            dispatch(actionCreators.screenStates("dashboard"))
          }}>
          <ArrowBack />
        </Center>
        <Square flex='1' bg='blue.500' >
          <Row>
            <Text strong  >{empName?.toUpperCase()}</Text>
          </Row>
        </Square>
        <Box >
          <Center w='40px' h='40px' bg={tssTheme.COLORS.PRIMARY} color='white'
            onClick={() => {
              getListofLocations(props.id, userData.tracking_type === "Human Resource" ? props.id : userData.device_id);
            }}
          >
            <Refresh />
          </Center>
        </Box>
      </Flex>
    )
  }



  const SearchBarView2 = () => {
    return (
      <Center flex='1' style={{
        backgroundColor: tssTheme.COLORS.PRIMARY,
        padding: 10, borderRadius: 1, marginTop: 5, marginBottom: 5
      }}>
        {/* <Text >Assignment</Text> */}


        <Flex color='white'>
          <Center  >
            <DatePicker onChange={onDateChange}
              allowClear={false}
              style={{ marginRight: 10, height: 40 }}
              defaultValue={moment(date, dateFormat)}
              format={dateFormat}
            />
            <TimePicker
              allowClear={false}
              value={moment('' + startTime, 'HH:mm:ss')}
              onChange={onStartTimeChange}
              style={{ marginRight: 10, height: 40 }}
            />
            <TimePicker
              allowClear={false}
              value={moment('' + endTime, 'HH:mm:ss')}
              onChange={onEndTimeChange}
              style={{ height: 40 }}

            />
            {/* <Button
              style={{ width: 150, margin: 10, borderRadius: 2 }}
              onClick={() => {
                console.log("END:" + endTime + " ID:" + userData.id + " R_ID:" + resource_history_id + " driver_id:" + userData.driver_id + " fleet_id" + userData.fleet_id + " device_id" + userData.device_id)
                getListofLocations(props.id, userData.tracking_type === "Human Resource" ? userData.driver_id : userData.device_id);
              }}>Search</Button> */}
            {isMapLoading ? <Spin /> : null}
          </Center>

          <Spacer />

          <Box flex='1'>
            <Col >

              {/* <Row>
     <Text strong >Employee Name:</Text>
 <Text  >{empName.toUpperCase()}</Text>
     </Row>     */}


            </Col>
          </Box>
        </Flex>


        {/* <Center style={{ marginTop: 10, marginLeft: 10 }}>
          {isFilterLoading ?
            <Center bg='white.500'> <Spin /> </Center> : null}
        </Center> */}


        {/* <Text style={{}} ><Text strong style={{ color: Theme.COLORS.WHITE }}>{filteredList.length}{" / "}{dummyFilteredList.length}</Text></Text> */}


      </Center>
    )
  }

  const LoadingSpinner = () => {
    return <div style={{
      width: '100%',
      height: '100%',
    }}>
      <div style={{ flexDirection: 'row' }}>

        <HeaderIcons />
        <SearchBarView2 />

      </div>
      <Center flex="1" style={{ marginTop: 10, height: 500 }}>
        <Flex flexDirection="row" >
          <div style={{ alignSelf: 'center' }}>
            <OrbitSpinner size={120} style={{}} color={tssTheme.COLORS.BLACK} />
            <Text style={{ textAlign: 'center' }} strong>Loading...</Text>
          </div>
        </Flex>
        <br />

      </Center>


    </div>
  }

  let markers =
    routes !== undefined &&
    routes &&
    routes.map((item, index) => ({
      id: index + 1,
      name: item.recorded_date,
      item: item,
      position: {
        lat: Number(item && item.latitude, 10),
        lng: Number(item && item.longitude, 10)
      }
    }));

  // console.log("@@@markser", routes);

  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = map => {
    const bounds = new window.google.maps.LatLngBounds();
    markers && markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);


  };

  return (
    isLoading ?
      <div style={{ height: "100%", width: "100%" }}>

        <HeaderIcons />
        <SearchBarView2 />

        <GoogleMap
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: "100%", height: "78vh", }}
        >

          {/* <Polyline
        path={
          routes !== undefined &&
          routes &&
          routes[0] &&
          routes.map(item => item.tracking_type !== 'site'?({
            lat: Number(item && item.latitude, 10),
            lng: Number(item && item.longitude, 10)
          }):null) 
        }
        options={{
          strokeColor: "#07966B",
          // strokeColor: "#4e342e",
          strokeOpacity: 1,
          strokeWeight: 5,
          icons: [
            {
              icon: "hello",
              offset: "0",
              repeat: "10px"
            }
          ]
        }}
      />  */}


          {/* The following Marker object will check if the index is the start or end */}
          {markers &&
            markers.map(({ id, item, position }, i, arr) => {
              const startIcon = {
                url:
                  "" + tssTheme.URL.startIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };

              const endIcon = {
                url:
                  "" + tssTheme.URL.endIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };

              const siteIcon = {
                url:
                  "" + tssTheme.URL.siteIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                // anchor: { x: 10, y: 10 },
                anchor: new window.google.maps.Point(15, 15),
                scale: 0.7
              };


              if (item?.tracking_type === 'site')
                return (<Marker
                  key="site"
                  position={position}
                  onClick={() => handleActiveMarker("site")}
                  // label={{ text: `START`, color: "black" }}
                  icon={siteIcon}
                >
                  {activeMarker === "site" ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        {/* {mapEquipmentDetailView()} */}
                        <Text strong>Site: {item?.sequence}</Text>
                        <br />
                        <Text strong style={{ textAlign: 'center', color: tssTheme.COLORS.BLACK }}>Recorded Date: {item?.recorded_date}</Text>
                        <br />
                        <Text strong style={{ textAlign: 'center', color: tssTheme.COLORS.BLACK }}>Latitude: {item?.latitude}</Text>
                        <br />
                        <Text strong style={{ textAlign: 'center', color: tssTheme.COLORS.BLACK }}>Longitude: {item?.longitude}</Text>
                        <br />
                        <Text strong style={{ textAlign: 'center', color: tssTheme.COLORS.BLACK }}>Type: {item?.tracking_type}</Text>
                        <br />
                        <br />
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
                )
              if (item?.sequence == 1)
                return (
                  <Marker
                    key="start"
                    position={position}
                    onClick={() => handleActiveMarker("start")}
                    // label={{ text: `START`, color: "black" }}
                    icon={startIcon}
                  >
                    {activeMarker === "start" ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                          <BoxView dateTime={item?.recorded_date}
                            data={userData} />
                          {/* { 
                   mapEquipmentDetailView(item.recorded_date)} */}
                          {/* <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>This is the start</Text>  <br/>
                   <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Recorded Date: {item.recorded_date}</Text> 
                  <br/>
                  <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Latitude: {item.latitude}</Text> 
                  <br/> 
                  <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Longitude: {item.longitude}</Text> 
                  <br/> 
               
                   <br/> */}
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              return (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                  label={{ text: i === arr.length - 1 ? null : `${(id - 1)}`, color: "white" }}
                  icon={i === arr.length - 1 ? endIcon : null}
                >
                  {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        <BoxView dateTime={item?.recorded_date}
                          data={userData} />
                        {/* { mapEquipmentDetailView(item.recorded_date)} */}
                        {/* <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Recorded Date: {item.recorded_date}</Text> 
                  <br/>
                  <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Latitude: {item.latitude}</Text> 
                  <br/> 
                  <Text strong style={{textAlign:'center',color:tssTheme.COLORS.BLACK}}>Longitude: {item.longitude}</Text> 
                  <br/> 
               
                   <br/> */}
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              );
            })}
        </GoogleMap>
      </div>
      : LoadingSpinner()
  );
}

export default MapScreen;