export const depositMoney = (amount) =>{
    return (dispatch) => {
        dispatch({
            type:'deposit',
            value:amount
        })
    }
}

export const withdrawMoney = (amount) =>{
    return (dispatch) => {
        dispatch({
            type:'withdraw',
            value:amount
        })
    }
}

export const userID = (id) =>{
    return (dispatch) => {
        dispatch({
            type:'userID',
            value:id
        })
    }
}



export const screenStates = (state) =>{
    return (dispatch) => {
        dispatch({
            type:'screenStates',
            value:state
        })
    }
}



export const dataHistory = (state) =>{
    return (dispatch) => {
        dispatch({
            type:'historyDataReducers',
            value:state
        })
    }
}
