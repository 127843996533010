import { combineReducers } from "redux";
import  amountReducers from './amountReducers'
import  screenStateReducers from './screenStateReducers'
import  historyDataReducers from './historyDataReducers'

const reducers = combineReducers({
    amount : amountReducers,
    screenStates :screenStateReducers,
    dataHistory:historyDataReducers

})

export default reducers;