export default {
  APP:{

    title:'TSS Tracking Portal',
    //testing
    MapAPIKey:'AIzaSyC2kfi4OcncEXGzdXCCoxchtAQeAOd15NE',
    //for Live
    TSSMapAPIKEY:'AIzaSyC2kfi4OcncEXGzdXCCoxchtAQeAOd15NE',
    refreshTime:1000 * 30, // 30 seconds
    
    menu1:'Dashboard',
    menu2:'Movements',
  },

  URL:{
    
    // OLD Server : http://157.245.98.161:8069
    search_vehicle_tracking_information:'https://tsstracker.me/search_vehicle_tracking_information',
    retrieve_vehicle_tracking_movement_information:'https://tsstracker.me/retrieve_vehicle_tracking_movement_information',
    retrieve_all_tracking_movement_information:'https://tsstracker.me/retrieve_all_tracking_movement_information',
    allVehicles:'https://tsstracker.me/retrieve_vehicle_tracking_information',
    filterVehicles:'https://tsstracker.me/retrieve_vehicle_tracking_information',
    siteList:'https://tsstracker.me/retrieve_all_sites',
    allEmployees:'https://tsstracker.me/retrieve_all_employees',
    allTeams:'https://tsstracker.me/retrieve_all_teams',
    allSites:'https://tsstracker.me/retrieve_all_sites',


    carIcon:'https://www.tsstracker.tawasolservices.com/assets/car.png',
    standing_up_manIcon:'https://www.tsstracker.tawasolservices.com/assets/standing_up_man.png',
    startIcon:'https://www.tsstracker.tawasolservices.com/assets/starting_point.png',
    endIcon:'https://www.tsstracker.tawasolservices.com/assets/stop_point.png',
    siteIcon:'https://www.tsstracker.tawasolservices.com/assets/tower4.png'

  },

  COLORS: {
    
    DEFAULT: '#155F7F',
    PRIMARY: '#155F7F',
    SECONDARY: '#F7FAFC',
    LABEL: '#FE2472',
    INFO: '#11CDEF',
    ERROR: '#F5365C',
    SUCCESS: '#2DCE89',
    WARNING: '#FB6340',
    /*not yet changed */
    MUTED: '#ADB5BD',
    INPUT: '#DCDCDC',
    INPUT_SUCCESS: '#7BDEB2',
    INPUT_ERROR: '#FCB3A4',
    ACTIVE: '#5E72E4', //same as primary
    BUTTON_COLOR: '#9C26B0', //wtf
    PLACEHOLDER: '#9FA5AA',
    SWITCH_ON: '#5E72E4',
    SWITCH_OFF: '#D4D9DD',
    GRADIENT_START: '#6B24AA',
    GRADIENT_END: '#AC2688',
    PRICE_COLOR: '#EAD5FB',
    BORDER_COLOR: '#E7E7E7',
    BLOCK: '#E7E7E7',
    ICON: '#172B4D',
    HEADER: '#525F7F',
    BORDER: '#CAD1D7',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    forestgreen:'#228B22',
    RED:'#FF0000',

    LightWhite:'#fafafa',
    lightGray:'#8d8d8d',

    borderColor:'#8B8B8B'
  }
};