import {tssTheme} from '../../constants'

export const siteSelectionFilter = (data,assignedType,movementType,insideType,attendanceType) => {

    return data.filter( (item) =>{
    return ((
      assignedType === "Yes"?
                    item.assigned_sites :assignedType === "No"? !item.assigned_sites : ((item.assigned_sites || !item.assigned_sites) )
    ) &&  (movementType === "Yes"?
    item.movements_count >= 1 :movementType === "No"? item.movements_count < 1 :(  item.movements_count >= 1 || item.movements_count < 1) )  
    && (insideType === "Yes"?
             item.inside_range >= 1 :insideType === "No"? item.inside_range < 1 : (item.inside_range >= 1 || item.inside_range < 1) )
    &&  (attendanceType === "Yes"? 
     item.leave_status === "Present" :
     attendanceType === "No"? item.leave_status !== "Present" : ((item.leave_status === "Present" || item.leave_status !== "Present" ) ) )

    //  &&  (event.length<=0? null:
    //  event.map((item1,index)=>{
    //      newArr = newArr.concat(data.filter(item => item.assigned_sites === item1.assigned_sites )) 
    // })
    //  )  
     // && (engineType === "Yes"? 
             //           item.engine_status === 'ON' : item.engine_status === 'OFF')
             //  && (engineIdleType === "Yes"? 
             //           parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 : parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0)
            
   )})
  }

  export const AssignedSiteFiltered = (event,data,assignedType,movementType,insideType,attendanceType) => {

    return data.filter( (item) =>{
       return ((
        event === "Yes"?
                       item.assigned_sites :event === "No"? !item.assigned_sites : ((item.assigned_sites || !item.assigned_sites) )
       ) &&  (movementType === "Yes"?
       item.movements_count >= 1 :movementType === "No"? item.movements_count < 1 :(  item.movements_count >= 1 || item.movements_count < 1) )  
       && (insideType === "Yes"?
                item.inside_range >= 1 :insideType === "No"? item.inside_range < 1 : (item.inside_range >= 1 || item.inside_range < 1) )
       &&  (attendanceType === "Yes"? 
        item.leave_status === "Present" :
        attendanceType === "No"? item.leave_status !== "Present" : ((item.leave_status === "Present" || item.leave_status !== "Present" ) ) )
        // && (engineType === "Yes"? 
                //           item.engine_status === 'ON' : item.engine_status === 'OFF')
                //  && (engineIdleType === "Yes"? 
                //           parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 : parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0)
               
      )})
   }

  export const handleMovementFiltered = (event,data,assignedType,movementType,insideType,attendanceType) => {

    return data.filter( (item) =>{
      return ((
       assignedType === "Yes"?
       item.assigned_sites :assignedType === "No"? !item.assigned_sites : ((item.assigned_sites || !item.assigned_sites) )
       )   
      && (event === "Yes"?
      item.movements_count >= 1 :event === "No"? item.movements_count < 1 :(  item.movements_count >= 1 || item.movements_count < 1) )  
       && (insideType === "Yes"?
       item.inside_range >= 1 :insideType === "No"? item.inside_range < 1 : (item.inside_range >= 1 || item.inside_range < 1) )
       &&  (attendanceType === "Yes"? 
       item.leave_status === "Present" :
       attendanceType === "No"? item.leave_status !== "Present" : ((item.leave_status === "Present" || item.leave_status !== "Present" ) ) )
       //  && (engineType === "Yes"? 
     //            item.engine_status === 'ON' : item.engine_status === 'OFF')
     //   && (engineIdleType === "Yes"? 
     //            parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 : parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0)
     
     )})
  }


  export const handleInsideRangeFiltered = (event,data,assignedType,movementType,insideType,attendanceType) => {

      return data.filter( (item) =>{
        return ((
         assignedType === "Yes"?
         item.assigned_sites :assignedType === "No"? !item.assigned_sites : ((item.assigned_sites || !item.assigned_sites) )
        )   
        &&  (movementType === "Yes"?
        item.movements_count >= 1 :movementType === "No"? item.movements_count < 1 :(  item.movements_count >= 1 || item.movements_count < 1) )  
   && (event === "Yes"?
   item.inside_range >= 1 :event === "No"? item.inside_range < 1 : (item.inside_range >= 1 || item.inside_range < 1) )
   &&  (attendanceType === "Yes"? 
        item.leave_status === "Present" :
        attendanceType === "No"? item.leave_status !== "Present" : ((item.leave_status === "Present" || item.leave_status !== "Present" ) ) )
        //  && (engineType === "Yes"? 
       //            item.engine_status === 'ON' : item.engine_status === 'OFF')
       //   && (engineIdleType === "Yes"? 
       //            parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 : parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0)
        )
       })
  }

  export const handleOnDutyFiltered = (event,data,assignedType,movementType,insideType,attendanceType) => {

    return data.filter( (item) =>{
      return ((
       assignedType === "Yes"?
       item.assigned_sites :assignedType === "No"? !item.assigned_sites : ((item.assigned_sites || !item.assigned_sites) )
      )  
      &&  (movementType === "Yes"?
      item.movements_count >= 1 :movementType === "No"? item.movements_count < 1 :(  item.movements_count >= 1 || item.movements_count < 1) )  
     && (insideType === "Yes"?
     item.inside_range >= 1 :insideType === "No"? item.inside_range < 1 : (item.inside_range >= 1 || item.inside_range < 1) )
     && (event === "Yes"? 
      item.leave_status === "Present" :
      event === "No"? item.leave_status !== "Present" : ((item.leave_status === "Present" || item.leave_status !== "Present" ) ) )

     
      //  && (engineType === "Yes"? 
     //            item.engine_status === 'ON' : item.engine_status === 'OFF')
     //   && (engineIdleType === "Yes"? 
     //            parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 : parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0)
      )
      // tracking_type
     })
}

export const EngineStatusFiltered = (event,data,assignedType,movementType,insideType,attendanceType,engineIdleType) => {

    return data.filter( (item) =>{
      return (
     //   assignedType === "Yes"?
     //                  item.assigned_sites : !item.assigned_sites 
     //  )     
     // && (movementType === "Yes"?
     //  item.movements_count >= 1 : item.movements_count < 1 )
     // && (insideType === "Yes"?
     //     item.inside_range >= 1 : item.inside_range < 1 )
     // && (attendanceType === "Yes"? 
     //     item.leave_status === "Present" : item.leave_status !== "Present")
     //&&
      (event === "Yes"? 
               item.engine_status === 'ON' :event === "No"? item.engine_status === 'OFF':(item.engine_status === 'ON' || item.engine_status === 'OFF'))
      && (engineIdleType === "Yes"? 
               parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 :
               engineIdleType === "Yes"?  parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0
               :( parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 || parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0 ))
      )
     })
  }

export const EngineIdleFiltered = (event,data,assignedType,movementType,insideType,attendanceType,engineType) => {

    return data.filter( (item) =>{
      return (
     
      //   assignedType === "Yes"?
     //      item.assigned_sites : !item.assigned_sites 
     //  )     
     // && (movementType === "Yes"?
     //  item.movements_count >= 1 : item.movements_count < 1 )
     // && (insideType === "Yes"?
     //     item.inside_range >= 1 : item.inside_range < 1 )
     // && (attendanceType === "Yes"? 
     //     item.leave_status === "Present" : item.leave_status !== "Present")
     //&&

      (engineType === "Yes"? 
      item.engine_status === 'ON' :engineType === "No"? item.engine_status === 'OFF':(item.engine_status === 'ON' || item.engine_status === 'OFF'))
      &&
      (event === "Yes"? 
      parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 :
      event === "Yes"?  parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0
      :( parseFloat(""+item.engine_on_since_when).toFixed(2)>=10.0 || parseFloat(""+item.engine_on_since_when).toFixed(2) < 10.0 ))
 )  
   })
  }

  export const handleNameSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.driver_name > b.driver_name) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.driver_name < b.driver_name) ? 1 : -1 :(null) )
   }


   export const handleEngineStatusSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.engine_status > b.engine_status) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.engine_status < b.engine_status) ? 1 : -1 :(null) )
   }

   export const handleInsideSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.inside_range > b.inside_range) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.inside_range < b.inside_range) ? 1 : -1 :(null) )
   }

   export const handleMovementSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.movements_count > b.movements_count) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.movements_count < b.movements_count) ? 1 : -1 :(null) )
   }

   export const handleDepartmentSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.hr_department > b.hr_department) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.hr_department < b.hr_department) ? 1 : -1 :(null) )
   }

   export const handleJobSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.hr_job > b.hr_job) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.hr_job < b.hr_job) ? 1 : -1 :(null) )
   }

   export const handleAccommodationSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.employee_accommodation > b.employee_accommodation) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.employee_accommodation < b.employee_accommodation) ? 1 : -1 :(null) )
   }

   export const handleKMsSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.today_km > b.today_km) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.today_km < b.today_km) ? 1 : -1 :(null) )
   }


   export const handleTeamSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.team > b.team) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.team < b.team) ? 1 : -1 :(null) )
   }

   export const handleAssignedSitesSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.assigned_sites > b.assigned_sites) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.assigned_sites < b.assigned_sites) ? 1 : -1 :(null) )
   }


   export const handleIdleTimeSort = (sort,filteredList) =>{
    if(sort % 2 == 0 )
    return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.engine_idle_time_with_no_movement > b.engine_idle_time_with_no_movement) ? 1 : -1 :(null) )
    else return filteredList.sort((a, b) => a.driver_name && b.driver_name ? ( a.engine_idle_time_with_no_movement < b.engine_idle_time_with_no_movement) ? 1 : -1 :(null) )
   }

  export const removeSiteDuplicate = (array) =>{
   array = array.reduce(function(a,b){
    if (a.indexOf(b.assigned_sites) < 0 ) a.push(b.assigned_sites);
    return a;
  },[])
 
  array = array.map(item=>{
    return {
      'assigned_sites':item,
    }
  })
  console.log(array)
  return ((array))  
  }


  export const removeEmpNameDuplicate = (array) =>{
    array = array.reduce(function(a,b){
     if (a.indexOf(b.resource_name) < 0 ) a.push(b.resource_name);
     return a;
   },[])
  
   array = array.map(item=>{
     return {
       'resource_name':item,
     }
   })
   console.log(array)
   return ((array))  
   }
 
   export const removeEmpNameDuplicate2 = (array) =>{
    array = array.reduce(function(a,b){
     if (a.indexOf(b.resource_name) < 0 ) a.push(b.resource_name);
     return a;
   },[])
  
   array = array.map(item=>{
     return {
       'resource_name':item,
     }
   })
  //  console.log(array)
   return ((array))  
   }
 
  export const removeTeamDuplicate = (array) =>{
    array = array.reduce(function(a,b){
     if (a.indexOf(b.team) < 0 ) a.push(b.team);
     return a;
   },[])
  
   array = array.map(item=>{
     return {
       'team':item,
     }
   })
   console.log(array)
   return ((array))  
   }
 


   export const removeSitesDuplicate = (array) =>{
    array = array.reduce(function(a,b){
     if (a.indexOf(b.assigned_sites) < 0 ) a.push(b.assigned_sites);
     return a;
   },[])
  
   array = array.map(item=>{
     return {
       'assigned_sites':item,
     }
   })
   console.log(array)
   return ((array))  
   }
 


  export const removeDepartmentDuplicate = (array) =>{

    array = array.reduce(function(a,b){
      if (a.indexOf(b.hr_department) < 0 ) a.push(b.hr_department);
      return a;
    },[])
   
    array = array.map(item=>{
      return {
        'hr_department':item
      }
    })
    return array;
  }
  export const removeJobDuplicate = (array) =>{
  
  array = array.reduce(function(a,b){
      if (a.indexOf(b.hr_job) < 0 ) a.push(b.hr_job);
      return a;
    },[])
   
    array = array.map(item=>{
      return {
        'hr_job':item
      }
    })

    return array;
  }



  export const removeRegionDuplicate = (array) =>{
  
    array = array.reduce(function(a,b){
        if (a.indexOf(b.region) < 0 ) if(b.region)a.push(b.region);
        return a;
      },[])
     
      array = array.map(item=>{
        return {
          'region':(item+"")
      }
      })
      return array;
    }
  
  

  export const getShuffledArr =(array) => {
    const newArray = [...array]
    const length = newArray.length
  
    for (let start = 0; start < length; start++) {
      const randomPosition = Math.floor((newArray.length - start) * Math.random())
      const randomItem = newArray.splice(randomPosition, 1)
  
      newArray.push(...randomItem)
    }
  
    return newArray
  }


  export const getListofEmployees=async()=>{
    fetch(tssTheme.URL.allEmployees, {
     method: 'POST',
     mode: 'cors',
     headers: {
     },
    })
    .then(function (response) {
     if (!response.ok) {
        return response.text().then(result => Promise.reject(new Error(result)));
     }
     return response.json();
 }).then((responseJson) => {
     return removeEmpNameDuplicate2(responseJson.result)

   })
   .catch((err)=>{
       console.log("err ",err)
       
   });
  }


  async function fetchUsers() {
    try{
      
    }catch(err){
      console.error(err); 
    }
  }
    



   