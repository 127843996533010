import React, { useState, useEffect , Component } from 'react';
import {  useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Link,Route } from "react-router-dom"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  AtomSpinner,
  BreedingRhombusSpinner,
  CirclesToRhombusesSpinner,
  FingerprintSpinner,
  FlowerSpinner,
  FulfillingBouncingCircleSpinner,
  FulfillingSquareSpinner,
  HalfCircleSpinner,
  HollowDotsSpinner,
  IntersectingCirclesSpinner,
  LoopingRhombusesSpinner,
  OrbitSpinner,
  PixelSpinner,
  RadarSpinner,
  ScalingSquaresSpinner,
  SelfBuildingSquareSpinner,
  SemipolarSpinner,
  SpringSpinner,
  SwappingSquaresSpinner,
  TrinityRingsSpinner} from 'react-epic-spinners'
  import { AccessAlarm, ThreeDRotation,
    AirportShuttle,
    Directions,
    Speed,
    BatteryUnknown,
    LocationOn,
    GasMeter,
    ElectricMeter,
    Countertops,
    MoreTime
  } from '@mui/icons-material';
import {
   Row,Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Avatar,
    Typography,
    Spin,
    Input,
    Button,
    DatePicker, Space ,Select
  } from "antd";
  import { ModalHover } from 'react-modal-hover'


  import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
  import Image from "react-bootstrap/Image";
  import Odometer from '../../assets/images/odometer.png'
  import kilometer from '../../assets/images/kilometer.png'

  import {tssTheme} from '../../constants'
  import { UserOutlined } from '@ant-design/icons';
  import moment from "moment";
  import Spinner from 'react-bootstrap/Spinner';
  import Dropdown from 'react-bootstrap/Dropdown';
  // import Button from 'react-bootstrap/Button';
  import { Flex, Spacer,Center,Square,Box,SimpleGrid , Stack,ButtonGroup} from '@chakra-ui/react'
  import FormGroup from '@mui/material/FormGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import Checkbox from '@mui/material/Checkbox';
  import {
    Editable,
    EditableInput,
    EditableTextarea,
    EditablePreview,
  } from '@chakra-ui/react'
  import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
  import { fadeIn } from 'react-animations'
  import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
// import {TestApp} from 'mniwnewnpm';


import Theme from '../../constants/Theme';
import { borderRadius } from '@mui/system';
import { BottomNavigation } from '@mui/material';
  const { Text, Title } = Typography;
  const { Option } = Select;
  
  const options = [
    { label: 'Idle Vehicle', value: 'Apple' },
    { label: 'Engine ON', value: 'Pear' },
    { label: 'Engine OFF', value: 'Orange' },
  ];
const containerStyle = {
  width: '100%',
  height: 600
};

const center = {
  lat: 25.2048,
  lng: 55.2708
};

const style: React.CSSProperties = { background: '#0092ff', padding: '8px 0' };

export default class GridView extends Component {
  constructor() {
    super(); 
    this.state = {
      isLoading: true,
      data:[],
      viewType:'1',
      width:window.innerWidth
  }
  }

    componentDidMount(){
       this.setState({data:this.props.data})
    }

       vehicleEngineDataBoxView(vehicleDetail) {
        return(
      <Box>
          <Flex>
            <Flex flexDirection='column'>
          <AirportShuttle  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }} />
          {/* <Text strong  style={{color:Theme.COLORS.BLACK}}> Fleet</Text> */}
             </Flex>
          <Text >  : {vehicleDetail.fleet_name}</Text>
         </Flex>
        
         <Box>
         <Avatar src={<Image src={kilometer}
                                        style={{  }} />} 
                                        size={25}
                                  />
          <Text > : {parseFloat(""+vehicleDetail.today_km).toFixed(2)}</Text>
         </Box>
         <Box>
         {/* <Speed  sx={{ fontSize: 25,color:tssTheme.COLORS.PRIMARY }}/> */}
         <Avatar src={<Image src={Odometer}
                                        style={{  }} />} 
                                        size={25}
                                  />
          <Text > : {parseFloat(""+vehicleDetail.end_odometer).toFixed(2)}</Text>
        </Box>
        
         <Box>
         <MoreTime  sx={{ fontSize: 25,color:tssTheme.COLORS.BLACK }} />
         <Text onMouseOver={()=><h3>Hello World</h3>}
               onMouseOut={() => {}}
          > : {parseFloat(""+vehicleDetail.engine_on_since_when).toFixed(2)}</Text>
    
        </Box> 

        <Box>
         <BatteryUnknown  sx={{ fontSize: 25,color:tssTheme.COLORS.BLACK }} />
         <Text > : {""+vehicleDetail.trip_state}</Text>
        </Box> 

        <Box>
           <LocationOn  sx={{ fontSize: 25,color:tssTheme.COLORS.SUCCESS }} />
           <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.current_latitude}" , {vehicleDetail.current_longitude}"</Text>
           </Box>

      </Box>
        )
      }
      
      

     vehicleGridView(){
        const data = this.props.data;
        console.log("DATA:"+data)
        return(
          <SimpleGrid    
          // columns={isMobile?[2,  2]:[2,  4]} 
           columns={[2,  4]} display="grid" gridGap={10} spacing='40px'>
          {data.map(function(vehicleDetail, i){
                   return <Card
                   style={{borderColor:vehicleDetail.engine_status==='OFF'?tssTheme.COLORS.BLACK:parseFloat(""+vehicleDetail.engine_on_since_when).toFixed(2)>=10.0?tssTheme.COLORS.RED:
                           tssTheme.COLORS.forestgreen,borderWidth:2}}
                          >
            <GridView data={vehicleDetail.engine_status} />
              <Flex color='white' gap='2'>
                <Box w='50px' bg='green.500'>
                  <Flex>
                <Avatar src={<Image src="https://bit.ly/dan-abramov" 
                                      style={{  }} />} 
                                      size={40}
                                />
                                </Flex>
                </Box>
                <Box bg='blue.500' size='150px' gap='2'>
                <Text strong>{vehicleDetail.driver_name}</Text>
                </Box>
                <Box p='2'>
                                  <ButtonGroup gap='2'>
                              <Stack spacing={2}>
                                <Button  style={{width:10,height:10,borderRadius:15,fontSize:1
                                  ,backgroundColor:vehicleDetail.engine_status==='OFF'?tssTheme.COLORS.SECONDARY:tssTheme.COLORS.forestgreen,}} >.</Button>
                                      <Text strong  style={{color:Theme.COLORS.BLACK,fontSize:10,textAlign:'center'}} >{vehicleDetail.engine_status}</Text>
                                </Stack>
                              </ButtonGroup>
                              </Box>
              </Flex>
    
                  <Stack spacing={3} style={{marginTop:10,}}>
                  {/* {this.vehicleEngineDataBoxView(vehicleDetail)} */}
                  <Box>
                  <LocationOn  sx={{ fontSize: 25,color:tssTheme.COLORS.SUCCESS }} />
                  <Text   style={{color:Theme.COLORS.BLACK}}> : {vehicleDetail.current_latitude}" , {vehicleDetail.current_longitude}"</Text>
                  </Box>
         
                  <Flex  color='white' style={{}}>
                   <Box flex='1'  >
                   <Box p='2' style={{alignContent:'flex-end'}}>
                     <Text p='1' strong underline style={{color:Theme.COLORS.RED,textAlign:'center',}} >
                       {vehicleDetail.report_type}</Text>
                     </Box>
                    </Box>
                    <Box  >
                    <Spacer />
                    <Box p='2'>
                    <Link 
                     to={{
                       pathname: '/mapping',
                       // search: '?sort=name',
                       // hash: '#the-hash',
                       id:vehicleDetail.resource_history_id,
                       place:vehicleDetail,
                       params: { id: 88 }
                     }}
       
                     >
                      <Button p='1' colorScheme='green' style={{width:'100%'}}    
                        onClick={()=>{this.setState({viewType:'0'})}}>
                            Live Track
                      </Button>
                    </Link>
    
                      </Box>
                    </Box>
                  </Flex> 
                  <Center style={{backgroundColor:tssTheme.COLORS.ICON,borderRadius:4}}>
                    <Text strong style={{color:'#FFF',textAlign:'center',}}>IDLE TIME: {parseFloat(""+vehicleDetail.engine_on_since_when).toFixed(2)}</Text>
                </Center>
                 </Stack>
                </Card>
          })}
          
        </SimpleGrid>
        )
      }



 vehicleEngineDataBoxViewWithoutIcons(places){
  return(
<Box>
    <Box>
    <Text strong style={{color:Theme.COLORS.BLACK}} >Fleet: </Text>
    <Text > : {places.fleet_name}</Text>
   </Box>
  
   <Box>
    <Text strong style={{color:Theme.COLORS.BLACK}} >Total KMs: </Text>
    <Text >{parseFloat(""+places.today_km).toFixed(2)}</Text>
   </Box>
   <Box>
    <Text strong style={{color:Theme.COLORS.BLACK}} >Odometer: </Text>
    <Text >{parseFloat(""+places.end_odometer).toFixed(2)}</Text>
   </Box>
   <Box>
    <Text strong style={{color:Theme.COLORS.BLACK}} >Total Idle Time:: </Text>
    <Text >{parseFloat(""+places.engine_on_since_when).toFixed(2)}</Text>
   </Box>
</Box>
  )
}


vehicleEngineData(places){
  return(

    <SimpleGrid   columns={[2,  2]} display="grid" gridGap={5}  spacing='10px'>

    <Card
       style={{borderColor:tssTheme.COLORS.PRIMARY,borderRadius:5,}}
              >
        <Box>
       <Text strong style={{color:Theme.COLORS.BLACK}} >Fleet: </Text>
       <Text >{places.fleet_name}</Text>
      </Box>
     
      </Card>
      <Card
       style={{borderColor:tssTheme.COLORS.PRIMARY,borderRadius:5,}}
       >
        <Box>
       <Text strong style={{color:Theme.COLORS.BLACK}} >Total KMs: </Text>
       <Text >{parseFloat(""+places.today_km).toFixed(2)}</Text>
      </Box>
      </Card>
      <Card
       style={{borderColor:tssTheme.COLORS.PRIMARY,borderRadius:5,}}
       >
         <Box>
       <Text strong style={{color:Theme.COLORS.BLACK}} >Odometer: </Text>
       <Text >{parseFloat(""+places.end_odometer).toFixed(2)}</Text>
      </Box>
      </Card>
          
      <Card
       style={{borderColor:tssTheme.COLORS.PRIMARY,borderRadius:5,}}
       >
         <Box>
       <Text strong style={{color:Theme.COLORS.BLACK}} >Total Idle Time:: </Text>
       <Text >{parseFloat(""+places.engine_on_since_when).toFixed(2)}</Text>
      </Box>
            </Card>

</SimpleGrid>  
   
  )
}

  Header(vehicleDetail){
    return(
      <Flex color='white' gap='2'>
      <Box w='50px' bg='green.500'>
        <Flex>
      <Avatar src={<Image src="https://bit.ly/dan-abramov" 
                            style={{  }} />} 
                            size={40}
                      />
                      </Flex>
      </Box>
      <Box bg='blue.500' size='150px' gap='2'>
      <Text strong>{vehicleDetail.driver_name} </Text>
      </Box>
      <Box p='2'>
                        <ButtonGroup gap='2'>
                    <Stack spacing={2}>
                      <Button  style={{width:10,height:10,borderRadius:15,fontSize:1
                        ,backgroundColor:vehicleDetail.engine_status==='OFF'?tssTheme.COLORS.SECONDARY:tssTheme.COLORS.forestgreen,}} >.</Button>
                            <Text strong  style={{color:Theme.COLORS.BLACK,fontSize:10,textAlign:'center'}} >{vehicleDetail.engine_status}</Text>
                      </Stack>
                    </ButtonGroup>
                    </Box>
             
    </Flex>

    )
  }

  Bottom(vehicleDetail){
    return(
      <Center style={{backgroundColor:tssTheme.COLORS.ICON,borderRadius:4}}>     
  <Text strong style={{color:'#FFF',textAlign:'center',}}>TOTAL IDLE TIME: {parseFloat(""+vehicleDetail.engine_idle_time_with_no_movement).toFixed(2)}</Text>
     </Center>
    )
  }

  pathNavigationBtn(vehicleDetail){
    return(
      <Flex  color='white' style={{}}>
      <Box flex='1'  >
      <Box p='2' style={{alignContent:'flex-end'}}>
        <Text p='1' strong underline style={{color:Theme.COLORS.RED,textAlign:'center',}} >
          {vehicleDetail.report_type}</Text>
        </Box>
       
       </Box>
       <Box  >
       <Spacer />
       <Box p='2'>
       <Link 
        to={{
          pathname: '/mapping',
          // search: '?sort=name',
          // hash: '#the-hash',
          id:vehicleDetail.resource_history_id,
          place:vehicleDetail,
          params: { id: 88 }
        }}

        >
         <Button p='1' colorScheme='green' style={{width:'100%'}}    
          //  onClick={()=>{setViewType("0")}}
           >
               Live Track
         </Button>
       </Link>

         </Box>
       </Box>
     </Flex> 
    )
  }

      render() {
        // const  vehicleDetail  = this.props.data;
        return (
          this.state.data.map((vehicleDetail, i)=>{
            return  <Card
            style={{borderColor:vehicleDetail.engine_status==='OFF'?tssTheme.COLORS.BLACK:parseFloat(""+vehicleDetail.engine_on_since_when).toFixed(2)>=10.0?tssTheme.COLORS.RED:
                    tssTheme.COLORS.forestgreen,borderWidth:2}}
                   >
          {this.Header(vehicleDetail) }
          <Stack spacing={3} style={{marginTop:10,}}>
          {this.vehicleEngineDataBoxView(vehicleDetail)}
          {this.pathNavigationBtn(vehicleDetail)}  
          {this.Bottom(vehicleDetail)}
          </Stack>
        </Card> 
       })
        // <Card
        //     style={{borderColor:this.state.data.engine_status==='OFF'?tssTheme.COLORS.BLACK:parseFloat(""+this.state.data.engine_on_since_when).toFixed(2)>=10.0?tssTheme.COLORS.RED:
        //             tssTheme.COLORS.forestgreen,borderWidth:2}}
        //            >
        //   {this.Header(this.state.data)}
        //   <Stack spacing={3} style={{marginTop:10,}}>
        //   {this.vehicleEngineDataBoxView(this.state.data)}
        //   {this.pathNavigationBtn(this.state.data)}  
        //   {this.Bottom(this.state.data)}
        //   </Stack>
        // </Card>

        )

      }

}
